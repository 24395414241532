import {TopicDTO, TopicRelationshipGraphDTO, TopicRelationshipMapDTO, TopicRelationshipsDTO,} from "../proto/topic_pb";
import {
    ActionType,
    InternalError,
    InternalErrorTypes,
    isError,
    IUIError,
    LogError,
    NewInternalError,
    NewUIError,
    NewUIErrorV2,
} from "../service/cartaError";
import {UUID_DTO} from "../proto/utils_pb";
import {
    convertDateToTimestamp,
    convertFromDTOToDate,
    convertFromDTOToID,
    convertFromDTOToString,
    convertTimestampToDate,
    ListItem,
    NewUUID,
    ValidateText,
} from "../utils/utils";
import {TopicRelation, TopicRelationshipData} from "./graph";
import {BaseModel, EntityKind, IOwnedModel} from "./BaseModel";
import {IFromDTO} from "./model";
import {TopicStatDTO} from "../proto/stats_pb";
import {CardStat} from "./Card";
import {IDisplayItem} from "./interfaces";
import {DEFAULT_TOPIC_COLOR} from "../consts";

export interface ITopic extends IOwnedModel<any> {
    _topic: string,
    _color: string,
    _description: string,
    _createdOn: Date,
    _updatedOn: Date,
    _archivedOn: Date
}

export class Topic extends BaseModel<Topic, TopicDTO> {
    topic: string = "";
    color?: string;
    description?: string;
    stats?: TopicStats = new TopicStats();
    archivedOn?: Date;
    
    constructor() {
        super();
        
        this.color = DEFAULT_TOPIC_COLOR;
    }
    
    static fromParts(userId: string, topic: string, id?: string, color?: string): Topic {
        let t = new Topic();
        t.userId = userId;
        if (id) {
            t.id = id;
        } else {
            t.id = NewUUID();
        }
        t.topic = topic;
        t.color = color;
        return t;
    }
    
    static fromString(topic: string): Topic {
        let t = new Topic();
        t.topic = topic;
        return t;
    }
    
    toListItem(): ListItem {
        return {
            id: this.id,
            title: this.topic,
            color: this.color,
        }
    }
    
    TYPE: EntityKind = EntityKind.Topic
    
    init(): Topic {
        return new Topic()
    }
    
    to1LineString(): String {
        return this.topic
    }
    
    static fromJSON(t: ITopic): Topic {
        let topic = new Topic();
        
        topic.id = `${t.id}`;
        topic.userId = `${t.userId}`
        topic.topic = `${t._topic}`
        topic.description = `${t._description}`
        topic.color = `${t._color}`
        topic.createdOn = new Date(t._createdOn)
        topic.updatedOn = new Date(t._updatedOn)
        topic.archivedOn = (t._archivedOn) ? new Date(t._archivedOn) : undefined;
        
        return topic
    }
    
    intoDTO(): IUIError | TopicDTO {
        let dto = new TopicDTO();
        dto.setId(new UUID_DTO().setValue(this.id));
        dto.setUserid(new UUID_DTO().setValue(this.userId));
        dto.setTopic(this.topic);
        dto.setColor(this.color ? this.color : "");
        dto.setCreatedon(convertDateToTimestamp(this.createdOn));
        dto.setUpdatedon(convertDateToTimestamp(this.updatedOn));
        dto.setArchivedon((this.archivedOn && convertDateToTimestamp(this.archivedOn!)));
        
        return dto;
    }
    
    clone(): Topic {
        // Create a new instance of Topic and assign the deep copied properties
        let topic = new Topic();
        
        topic.id = this.id;
        topic.userId = this.userId;
        topic.topic = this.topic;
        topic.color = this.color;
        topic.description = this.description;
        topic.createdOn = new Date(this.createdOn.getTime())
        topic.updatedOn = new Date(this.updatedOn.getTime())
        topic.archivedOn = this.archivedOn ? new Date(this.archivedOn.getTime()) : undefined;
        
        return topic;
    }
    
    sanitize(): Topic {
        if (this.color === "") {
            this.color = undefined;
        }
        
        if (this.description === "") {
            this.description = undefined;
        }
        
        this.topic = this.topic.trim();
        this.color = this.color ? this.color.trim() : undefined;
        return this;
    }
    
    validateTopicString(topic: string): boolean {
        if (!topic) {
            return false
        }
        // regex that includes letters, numbers and the following special chars (
        return ValidateText(topic);
    }
    
    customValidate(): IUIError | Topic {
        if (!this.topic || this.topic === "") {
            const message = "topic text cannot be empty";
            const logMssage = `topic: (Id = ${this.id}) text cannot be empty`;
            
            return NewUIErrorV2(
                ActionType.Validate,
                this.TYPE,
                "",
                logMssage,
                message
            );
        }
        
        return this.sanitize();
    }
    
    fromDTO(dto: TopicDTO): void | IUIError {
        this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
        this.userId = convertFromDTOToID('userId', this.TYPE, dto.getUserid());
        this.topic = convertFromDTOToString('topic', this.TYPE, dto.getTopic(), false);
        this.color = convertFromDTOToString('color', this.TYPE, dto.getColor(), true);
        this.createdOn = convertFromDTOToDate('createdOn', this.TYPE, dto.getCreatedon())!;
        this.updatedOn = convertFromDTOToDate('updatedOn', this.TYPE, dto.getUpdatedon())!;
        this.archivedOn = convertFromDTOToDate('archivedOn', this.TYPE, dto.getArchivedon(), true);
    }
    
    toDisplayable(): IDisplayItem {
        return {
            id: this.id,
            title: this.topic,
            color: this.color
        };
    }
}

export const validateTopic = (topic: Topic): Topic | IUIError => {
    if (!topic.id) {
        return NewUIError(
            "validateTopic",
            InternalErrorTypes.InvalidTopic,
            "topic is missing id",
            "topic is missing id"
        );
    }
    
    if (!topic.userId) {
        const message = "topic is missing userId";
        const logMssage = `topic: (Id = ${topic.id}) is missing userId`;
        return NewUIError(
            "validateTopic",
            InternalErrorTypes.InvalidTopic,
            logMssage,
            message
        );
    }
    
    if (!topic.topic || topic.topic === "") {
        const message = "topic text cannot be empty";
        const logMssage = `topic: (Id = ${topic.id}) text cannot be empty`;
        return NewUIError(
            "validateTopic",
            InternalErrorTypes.InvalidTopic,
            logMssage,
            message
        );
    }
    
    if (topic.color === "") {
        topic.color = undefined;
    }
    
    if (topic.description === "") {
        topic.description = undefined;
    }
    
    return topic;
};

export const convertTopicToDTO = (topic: Topic): TopicDTO => {
    let dto = new TopicDTO();
    
    dto.setId(new UUID_DTO().setValue(topic.id));
    dto.setUserid(new UUID_DTO().setValue(topic.userId));
    dto.setTopic(topic.topic);
    dto.setColor(topic.color ? topic.color : DEFAULT_TOPIC_COLOR);
    dto.setCreatedon(convertDateToTimestamp(topic.createdOn));
    dto.setUpdatedon(convertDateToTimestamp(topic.updatedOn));
    if (topic.archivedOn) {
        dto.setArchivedon(convertDateToTimestamp(topic.archivedOn));
    } else {
        dto.setArchivedon(undefined);
    }
    
    return dto;
};

export const convertDTOToTopicRelation = (
    dto: TopicRelationshipMapDTO
): TopicRelation | IUIError => {
    if (dto.getId()) {
        return {
            id: dto.getId()!.getValue(),
            relationship: dto.getRelationship(),
        } as TopicRelation;
    } else {
        return NewUIErrorV2(
            ActionType.ConvertFromDTO,
            EntityKind.TopicRelation,
            `failed to get id for TopicRelationshipMapDTO (value = ${dto})`
        );
    }
};

export const convertTopicRelationDataToDTO = (
    relationship: TopicRelationshipData
): TopicRelationshipGraphDTO => {
    relationship = sanitizeRelationship(relationship);
    
    let dto = new TopicRelationshipGraphDTO();
    
    relationship.graph.forEach((relations, key) => {
        let relationMaps = relations.map(convertTopicRelationToDTO);
        dto
            .getGraphMap()
            .set(key, new TopicRelationshipsDTO().setMapList(relationMaps));
    });
    
    relationship.topics.forEach((topic, key) => {
        dto.getTopicsMap().set(key, convertTopicToDTO(topic));
    });
    
    return dto;
};

export const convertTopicRelationToDTO = (
    relation: TopicRelation
): TopicRelationshipMapDTO => {
    let relationshipMapDTO = new TopicRelationshipMapDTO();
    relationshipMapDTO.setId(new UUID_DTO().setValue(relation.id));
    relationshipMapDTO.setRelationship(relation.relationship);
    
    return relationshipMapDTO;
};

export const convertDTOToTopicGraph = (
    dto: TopicRelationshipGraphDTO
): TopicRelationshipData | InternalError => {
    let data = new TopicRelationshipData();
    
    dto.getGraphMap().forEach((relationships: TopicRelationshipsDTO, parentId: string) => {
        let relations: TopicRelation[] = [];
        
        relationships.getMapList().forEach((v) => {
            let relation = convertDTOToTopicRelation(v);
            
            if (!isError(relation)) {
                if (v.getId()) {
                    relations.push(relation as TopicRelation);
                } else {
                    LogError(
                        "convertDTOToTopicGraph",
                        InternalErrorTypes.ConvertTopicGraph,
                        `invalid ID of child (topic = ${v}) when accessing child topics of parent: ${parentId}`
                    );
                }
            } else {
                LogError(
                    "convertDTOToTopicGraph",
                    InternalErrorTypes.ConvertTopicGraph,
                    `failed to convert TopicRelation (value = ${relation}) when accessing child topics of parent: ${parentId}`
                );
            }
        });
        
        data.graph.set(parentId, relations);
    });
    
    dto.getTopicsMap().forEach((dto: TopicDTO, topicId: string) => {
        let convertedTopic = new Topic();
        convertedTopic.fromDTO(dto);
        
        if (!isError(convertedTopic)) {
            data.topics.set(topicId, convertedTopic as Topic);
        } else {
            NewInternalError(
                "convertDTOToTopicGraph",
                InternalErrorTypes.ConvertTopicGraph,
                `failed to convert TopicDTO (value = ${dto}) to topic for topic (id = ${topicId})`
            );
        }
    });
    
    return data;
};


export const sanitizeRelationship = (
    relationship: TopicRelationshipData
): TopicRelationshipData => {
    relationship.topics.forEach((v, k) => {
        v = (v.sanitize());
    });
    
    return relationship;
};


export class TopicStats implements IFromDTO<TopicStatDTO> {
    private _avgConfidence: number | undefined;
    private _sm2AvgInterval: number = 1;
    private _sm2AvgRepetition: number = 1;
    private _sm2RecentReview: Date | undefined;
    private _sm2NextCardReview: string | undefined;
    private _sm2NextReview: Date | undefined;
    private _numRelationships: number = 0;
    private _mostReviewedCard: string | undefined;
    private _leastReviewedCard: string | undefined;
    private _weakestCard: string | undefined;
    private _strongestCard: string | undefined;
    private _numResources: number = 0;
    private _reviewStats: number = 0;
    
    constructor() {
    }
    
    public fromDTO(dto: TopicStatDTO) {
        this._avgConfidence = dto.getAvgquality();
        this._sm2AvgRepetition = dto.getAvgrepetition();
        this._sm2AvgInterval = dto.getAvginterval();
        this._numRelationships = dto.getNumrelationships();
        dto.getCardstatsList().map((x) => {
            let stat = new CardStat();
            stat.fromDTO(x);
            
            return stat;
        });
        // this._numResources = dto.getNumresources()
        
        // dto.getCardstatsList() // TODO - Setup this
        if (dto.getRecentreview()) {
            this._sm2RecentReview = convertTimestampToDate(dto.getRecentreview()!);
        }
        if (dto.getNextreview()) {
            this._sm2NextReview = convertTimestampToDate(dto.getNextreview()!);
        }
        if (dto.getNextcardreview()) {
            this._sm2NextCardReview = dto.getNextcardreview()!.getValue();
        }
        if (dto.getMostreviewedcard()) {
            this._mostReviewedCard = dto.getMostreviewedcard()!.getValue();
        }
        if (dto.getLeastreviewedcard()) {
            this._leastReviewedCard = dto.getLeastreviewedcard()!.getValue();
        }
        if (dto.getWeakestcard()) {
            this._weakestCard = dto.getWeakestcard()!.getValue();
        }
        if (dto.getStrongestcard()) {
            this._strongestCard = dto.getWeakestcard()!.getValue();
        }
    }
    
    get avgConfidence(): number | undefined {
        return this._avgConfidence;
    }
    
    set avgConfidence(value: number | undefined) {
        this._avgConfidence = value;
    }
    
    get sm2AvgInterval(): number {
        return this._sm2AvgInterval;
    }
    
    set sm2AvgInterval(value: number) {
        this._sm2AvgInterval = value;
    }
    
    get sm2AvgRepetition(): number {
        return this._sm2AvgRepetition;
    }
    
    set sm2AvgRepetition(value: number) {
        this._sm2AvgRepetition = value;
    }
    
    get sm2RecentReview(): Date | undefined {
        return this._sm2RecentReview;
    }
    
    set sm2RecentReview(value: Date | undefined) {
        this._sm2RecentReview = value;
    }
    
    get numRelationships(): number {
        return this._numRelationships;
    }
    
    set numRelationships(value: number) {
        this._numRelationships = value;
    }
    
    get mostReviewedCard(): string | undefined {
        return this._mostReviewedCard;
    }
    
    set mostReviewedCard(value: string | undefined) {
        this._mostReviewedCard = value;
    }
    
    get leastReviewedCard(): string | undefined {
        return this._leastReviewedCard;
    }
    
    set leastReviewedCard(value: string | undefined) {
        this._leastReviewedCard = value;
    }
    
    get weakestCard(): string | undefined {
        return this._weakestCard;
    }
    
    set weakestCard(value: string | undefined) {
        this._weakestCard = value;
    }
    
    get strongestCard(): string | undefined {
        return this._strongestCard;
    }
    
    set strongestCard(value: string | undefined) {
        this._strongestCard = value;
    }
    
    get numResources(): number {
        return this._numResources;
    }
    
    set numResources(value: number) {
        this._numResources = value;
    }
    
    get sm2NextCardReview(): string | undefined {
        return this._sm2NextCardReview;
    }
    
    set sm2NextCardReview(value: string | undefined) {
        this._sm2NextCardReview = value;
    }
    
    get sm2NextReview(): Date | undefined {
        return this._sm2NextReview;
    }
    
    set sm2NextReview(value: Date | undefined) {
        this._sm2NextReview = value;
    }
}
