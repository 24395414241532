import {ActionType, InternalErrorTypes, isError, IUIError, NewUIError, NewUIErrorV2,} from "../service/cartaError";
import {Card} from "./Card";
import {
    convertDateToTimestamp,
    convertFromDTOToDate,
    convertFromDTOToID,
    convertTimestampToDate,
    ListItem
} from "../utils/utils";
import {EnumCardKindDTO, UUID_DTO} from "../proto/utils_pb";
import {ReviewSM2CardCompositeDTO, ReviewSM2CardDTO} from "../proto/reviewSM2_pb";
import {v4 as uuidv4} from "uuid";
import {IFromDTO, IIntoDTO} from "./model";
import {ReviewSM2CardStatDTO} from "../proto/stats_pb";
import {CardDTO} from "../proto/card_pb";
import {IReviewCard} from "./Review";
import {IDisplayItem} from "./interfaces";
import {BaseModel, EntityKind} from "./BaseModel";
import {ICard, IComposite} from "./CardComposite";
import {MAX_CARD_QUALITY, MIN_CARD_QUALITY} from "../consts";

export class ReviewSM2Card extends BaseModel<ReviewSM2Card, ReviewSM2CardDTO>
    implements IReviewCard {
    
    TYPE: EntityKind = EntityKind.ReviewSM2Card;
    private _quality?: number;
    private _interval?: number;
    private _easiniess?: number;
    private _repetitions: number;
    private _startAt?: Date;
    private _endAt?: Date;
    private _nextReview?: Date;
    private _composite: ReviewSM2CardComposite = new ReviewSM2CardComposite()
    private _cardKind: EnumCardKindDTO = EnumCardKindDTO.CARDKIND_LANGUAGE
    private _archivedOn?: Date;
    
    toListItem(): ListItem {
        return {
            id: this.id,
            title: this._composite.card.front,
            metadata1: this._composite.card.back,
        }
    }
    
    constructor() {
        super();
        
        this.reviewId = "";
        this.cardId = "";
        // this._card = new Card();
        this._quality = undefined;
        this._interval = undefined;
        this._easiniess = undefined;
        this._repetitions = 0;
        this._startAt = undefined;
        this._endAt = undefined;
        this._nextReview = undefined;
        this._archivedOn = undefined;
        
        this.cardId = "";
    }
    
    static setup(card: ICard): ReviewSM2Card {
        let reviewCard = new ReviewSM2Card();
        reviewCard.cardId = card.id
        
        switch (card.TYPE) {
            case EntityKind.Card:
                reviewCard._cardKind = EnumCardKindDTO.CARDKIND_STANDARD;
                break;
            case EntityKind.CardLang:
                reviewCard._cardKind = EnumCardKindDTO.CARDKIND_LANGUAGE;
                break;
            default:
                reviewCard._cardKind = EnumCardKindDTO.CARDKIND_STANDARD;
        }
        
        let composite = new ReviewSM2CardComposite();
        composite.setup(card, reviewCard.id, 0)
        
        reviewCard.composite = composite;
        
        return reviewCard
    }
    
    public sanitize(): ReviewSM2Card {
        if (this.cardId === "" && this.composite.card.id !== "") {
            this.cardId = this.composite.card.id;
        }
        
        return this;
    }
    
    fromDTO = (dto: ReviewSM2CardDTO): void | IUIError => {
        this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
        this.userId = convertFromDTOToID('userId', this.TYPE, dto.getUserid());
        this.cardId = convertFromDTOToID('cardId', this.TYPE, dto.getCardid());
        this.reviewId = convertFromDTOToID('reviewId', this.TYPE, dto.getReviewid());
        this.createdOn = convertFromDTOToDate('createdOn', this.TYPE, dto.getCreatedon())!
        this.updatedOn = convertFromDTOToDate('updatedOn', this.TYPE, dto.getUpdatedon())!
        this.archivedOn = convertFromDTOToDate('archivedOn', this.TYPE, dto.getArchivedon(), true)
        this.startAt = convertFromDTOToDate('startAt', this.TYPE, dto.getStartAt(), true)
        this.endAt = convertFromDTOToDate('endAt', this.TYPE, dto.getEndAt(), true)
        this.nextReview = convertFromDTOToDate('nextReview', this.TYPE, dto.getNextReview(), true)
        this._easiniess = dto.getEasiness() / 100;
        this._interval = dto.getInterval();
        this._quality = dto.getQuality();
        this._repetitions = dto.getRepetitions();
        
        let composite = new ReviewSM2CardComposite();
        if (dto.getComposite()) {
            composite.fromDTO(dto.getComposite()!);
        } else {
            return NewUIError(
                "fromDTO",
                InternalErrorTypes.InvalidReviewSM2Card,
                `composite is undefined '' - reviewSM2Card: ${dto}"`
            );
        }
        
        this._composite = composite;
    };
    
    intoDTO(): IUIError | ReviewSM2CardDTO {
        let dto: ReviewSM2CardDTO = new ReviewSM2CardDTO();
        
        const validate = this.customValidate();
        if (isError(validate)) {
            return validate as IUIError;
        }
        const valid = validate as ReviewSM2Card;
        
        dto.setId(new UUID_DTO().setValue(valid.id));
        dto.setReviewid(new UUID_DTO().setValue(valid.reviewId));
        dto.setUserid(new UUID_DTO().setValue(valid.userId));
        dto.setCardid(new UUID_DTO().setValue(valid.cardId));
        
        if (this.composite) {
            const compositeDTO = this.composite.intoDTO();
            if (isError(compositeDTO)) {
                return compositeDTO as IUIError;
            }
            dto.setComposite(compositeDTO as ReviewSM2CardCompositeDTO);
            // dto.setCardid(new UUID_DTO().setValue(this.composite.card.id));
        }
        
        dto.setCreatedon(convertDateToTimestamp(valid.createdOn));
        dto.setUpdatedon(convertDateToTimestamp(valid.updatedOn));
        
        if (valid.interval) {
            dto.setInterval(valid.interval);
        }
        if (valid.easiniess) {
            dto.setEasiness(valid.easiniess * 100);
        }
        dto.setRepetitions(valid.repetitions);
        
        if (valid.quality !== undefined && valid.quality > -1) {
            dto.setQuality(valid.quality);
        } else {
            throw NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card quality has not been validated");
        }
        
        if (valid.endAt) {
            dto.setEndAt(convertDateToTimestamp(valid.endAt));
        }
        if (valid.startAt) {
            dto.setStartAt(convertDateToTimestamp(valid.startAt));
        }
        if (valid.archivedOn) {
            dto.setArchivedon(convertDateToTimestamp(valid.archivedOn));
        }
        if (valid.nextReview) {
            dto.setNextReview(convertDateToTimestamp(valid.nextReview));
        }
        
        return dto;
    }
    
    clone(): ReviewSM2Card {
        let clonedCard = new ReviewSM2Card();
        
        clonedCard.id = this.id;
        clonedCard.userId = this.userId;
        clonedCard.cardId = this.cardId;
        clonedCard.reviewId = this.reviewId;
        clonedCard.quality = this.quality;
        clonedCard.interval = this.interval;
        clonedCard.easiniess = this.easiniess;
        clonedCard.repetitions = this.repetitions;
        clonedCard.startAt = this.startAt;
        clonedCard.endAt = this.endAt;
        clonedCard.nextReview = this.nextReview;
        clonedCard.archivedOn = this.archivedOn;
        clonedCard.composite = this.composite;
        clonedCard.createdOn = this.createdOn;
        clonedCard.updatedOn = this.updatedOn;
        
        return clonedCard;
    }
    
    customValidate(): IUIError | ReviewSM2Card {
        if (!this.id) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card is missing id");
        }
        
        if (!this.userId) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card is missing userId");
        }
        
        if (this.reviewId === "") {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card reviewId is empty")
        }
        
        if (this.cardId === "") {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card cardId is empty")
        }
        
        if (this.quality === undefined) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card quality is empty")
        }
        
        if (this.quality > MAX_CARD_QUALITY) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card quality is too high")
        }
        
        if (this.quality < MIN_CARD_QUALITY) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card quality is too low")
        }
        
        return this;
    }
    
    to1LineString(): String {
        return "";
    }
    
    toDisplayable(): IDisplayItem {
        return {
            id: this.id,
            title: this.composite ? this.composite.card.front : "",
        };
    }
    
    cardId: string;
    reviewId: string;
    
    init(): ReviewSM2Card {
        return new ReviewSM2Card()
    }
    
    get cardKind(): EnumCardKindDTO {
        return this._cardKind;
    }
    
    set cardKind(value: EnumCardKindDTO) {
        this._cardKind = value;
    }
    
    get composite(): ReviewSM2CardComposite {
        return this._composite;
    }
    
    set composite(value: ReviewSM2CardComposite) {
        this._composite = value;
    }
    
    get repetitions(): number {
        return this._repetitions;
    }
    
    set repetitions(value: number) {
        this._repetitions = value;
    }
    
    get quality(): number | undefined {
        return this._quality;
    }
    
    set quality(value: number | undefined) {
        this._quality = value;
    }
    
    get interval(): number | undefined {
        return this._interval;
    }
    
    set interval(value: number | undefined) {
        this._interval = value;
    }
    
    get easiniess(): number | undefined {
        return this._easiniess;
    }
    
    set easiniess(value: number | undefined) {
        this._easiniess = value;
    }
    
    get startAt(): Date | undefined {
        return this._startAt;
    }
    
    set startAt(value: Date | undefined) {
        this._startAt = value;
    }
    
    get endAt(): Date | undefined {
        return this._endAt;
    }
    
    set endAt(value: Date | undefined) {
        this._endAt = value;
    }
    
    get nextReview(): Date | undefined {
        return this._nextReview;
    }
    
    set nextReview(value: Date | undefined) {
        this._nextReview = value;
    }
    
    get archivedOn(): Date | undefined {
        return this._archivedOn;
    }
    
    set archivedOn(value: Date | undefined) {
        this._archivedOn = value;
    }
}

export class ReviewSM2CardStat implements IFromDTO<ReviewSM2CardStatDTO> {
    id: string = "";
    cardId: string = "";
    reviewId: string = "";
    quality?: number;
    interval?: number;
    repetitions?: number;
    endAt?: Date;
    startAt?: Date;
    
    fromDTO(reviewCard: ReviewSM2CardStatDTO): void | IUIError {
        if (reviewCard.getId()) {
            if (reviewCard.getId()!.getValue()) {
                this.id = reviewCard.getId()!.getValue();
            } else {
                return NewUIError(
                    "fromDTO",
                    InternalErrorTypes.InvalidReviewCard,
                    `getId is empty '' - reviewCard: ${reviewCard}"`
                );
            }
        } else {
            return NewUIError(
                "fromDTO",
                InternalErrorTypes.InvalidReviewCard,
                `getId is undefined '' - reviewCard: ${reviewCard}"`
            );
        }
        
        if (reviewCard.getCardid()) {
            if (reviewCard.getCardid()!.getValue()) {
                this.cardId = reviewCard.getCardid()!.getValue();
            } else {
                return NewUIError(
                    "fromDTO",
                    InternalErrorTypes.InvalidReviewCard,
                    `getCardid is empty '' - reviewCard: ${reviewCard}"`
                );
            }
        } else {
            return NewUIError(
                "fromDTO",
                InternalErrorTypes.InvalidReviewCard,
                `getCardid is undefined '' - reviewCard: ${reviewCard}"`
            );
        }
        
        if (reviewCard.getReviewid()) {
            if (reviewCard.getReviewid()!.getValue()) {
                this.reviewId = reviewCard.getReviewid()!.getValue();
            } else {
                return NewUIError(
                    "fromDTO",
                    InternalErrorTypes.InvalidReviewCard,
                    `getReviewid is empty '' - reviewCard: ${reviewCard}"`
                );
            }
        } else {
            return NewUIError(
                "fromDTO",
                InternalErrorTypes.InvalidReviewCard,
                `getReviewid is undefined '' - reviewCard: ${reviewCard}"`
            );
        }
        
        this.repetitions = reviewCard.getRepetitions();
        this.quality = reviewCard.getQuality();
        this.interval = reviewCard.getInterval();
        if (reviewCard.getStartat()) {
            this.startAt = convertTimestampToDate(reviewCard.getStartat()!);
        }
        if (reviewCard.getEndat()) {
            this.endAt = convertTimestampToDate(reviewCard.getEndat()!);
        }
    }
}

export interface IReviewCardComposite<C extends ICard> extends IComposite {
    card: C,
    
    init(): IReviewCardComposite<C>
    
    setup(card: ICard, id: string, ord: number): void
}

export class ReviewSM2CardComposite implements IReviewCardComposite<ICard>,
    IIntoDTO<ReviewSM2CardCompositeDTO>, IFromDTO<ReviewSM2CardCompositeDTO> {
    get id(): string {
        return this._id;
    }
    
    set id(value: string) {
        this._id = value;
    }
    
    get ord(): number {
        return this._ord;
    }
    
    set ord(value: number) {
        this._ord = value;
    }
    
    setup(card: ICard, id: string, ord: number) {
        this._card = card;
        this.id = id;
        this.ord = ord;
    }
    
    init(): IReviewCardComposite<ICard> {
        return new ReviewSM2CardComposite()
    }
    
    private _id: string = "";
    private _ord: number = 0;
    private _card: ICard = new Card();
    
    TYPE: EntityKind = EntityKind.ReviewSM2CardComposite;
    
    constructor() {
        this._id = uuidv4();
    }
    
    public sanitize(): ReviewSM2CardComposite {
        return this;
    }
    
    
    to1LineString(): String {
        return "";
    }
    
    toDisplayable(): IDisplayItem {
        return {
            id: this._id,
            title: this._card?.front || "",
        };
    }
    
    
    fromDTO(t: ReviewSM2CardCompositeDTO): void | IUIError {
        let card = new Card()
        if (t.getCard()) {
            card.fromDTO(t.getCard()!)
        } else {
            return NewUIError(
                "fromDTO",
                InternalErrorTypes.InvalidReviewSM2Card,
                `card is undefined '' - reviewSM2Card: ${t}"`
            );
        }
        
        this._id = convertFromDTOToID('id', EntityKind.ReviewSM2CardComposite, t.getSupertypeId())
        this._card = card
    }
    
    intoDTO(): IUIError | ReviewSM2CardCompositeDTO {
        let dto = new ReviewSM2CardCompositeDTO();
        
        let card_dto = new CardDTO();
        if (this._card) {
            card_dto = this._card.intoDTO() as CardDTO;
        }
        
        dto.setCard(card_dto)
        
        return dto
    }
    
    get card(): ICard {
        return this._card;
    }
    
    set card(value: ICard) {
        this._card = value;
    }
}

// export class ReviewSM2CardStat implements IFromDTO<ReviewSM2CardStatDTO> {
//   id: string = "";
//   cardId: string = "";
//   reviewId: string = "";
//   quality?: number;
//   interval?: number;
//   repetitions?: number;
//   endAt?: Date;
//   startAt?: Date;
//
//   fromDTO(reviewCard: ReviewSM2CardStatDTO): void | IUIError {
//     if (reviewCard.getId()) {
//       if (reviewCard.getId()!.getValue()) {
//         this.id = reviewCard.getId()!.getValue();
//       } else {
//         return NewUIError(
//             "fromDTO",
//             InternalErrorTypes.InvalidReviewCard,
//             `getId is empty '' - reviewCard: ${reviewCard}"`
//         );
//       }
//     } else {
//       return NewUIError(
//           "fromDTO",
//           InternalErrorTypes.InvalidReviewCard,
//           `getId is undefined '' - reviewCard: ${reviewCard}"`
//       );
//     }
//
//     if (reviewCard.getCardid()) {
//       if (reviewCard.getCardid()!.getValue()) {
//         this.cardId = reviewCard.getCardid()!.getValue();
//       } else {
//         return NewUIError(
//             "fromDTO",
//             InternalErrorTypes.InvalidReviewCard,
//             `getCardid is empty '' - reviewCard: ${reviewCard}"`
//         );
//       }
//     } else {
//       return NewUIError(
//           "fromDTO",
//           InternalErrorTypes.InvalidReviewCard,
//           `getCardid is undefined '' - reviewCard: ${reviewCard}"`
//       );
//     }
//
//     if (reviewCard.getReviewid()) {
//       if (reviewCard.getReviewid()!.getValue()) {
//         this.reviewId = reviewCard.getReviewid()!.getValue();
//       } else {
//         return NewUIError(
//             "fromDTO",
//             InternalErrorTypes.InvalidReviewCard,
//             `getReviewid is empty '' - reviewCard: ${reviewCard}"`
//         );
//       }
//     } else {
//       return NewUIError(
//           "fromDTO",
//           InternalErrorTypes.InvalidReviewCard,
//           `getReviewid is undefined '' - reviewCard: ${reviewCard}"`
//       );
//     }
//
//     this.repetitions = reviewCard.getRepetitions();
//     this.quality = reviewCard.getQuality();
//     this.interval = reviewCard.getInterval();
//     if (reviewCard.getStartat()) {
//       this.startAt = convertTimestampToDate(reviewCard.getStartat()!);
//     }
//     if (reviewCard.getEndat()) {
//       this.endAt = convertTimestampToDate(reviewCard.getEndat()!);
//     }
//   }
// }
