import {CardCompositeDTO, CardDTO, CardOriginDTO, CardStatusDTO} from "../proto/card_pb";
import {ActionType, InternalErrorTypes, isError, IUIError, NewUIError, NewUIErrorV2,} from "../service/cartaError";
import {
    convertDateToTimestamp,
    convertFromDTOToDate,
    convertFromDTOToID,
    convertTimestampToDate,
    ListItem
} from "../utils/utils";
import {UUID_DTO} from "../proto/utils_pb";
import {v4 as uuidv4} from "uuid";
import {IFromDTO} from "./model";
import {CardStatDTO} from "../proto/stats_pb";
import {ReviewManualCardStat} from "./ReviewManualCard";
import {ReviewSM2CardStat} from "./ReviewSM2Card";
import {IDisplayItem} from "./interfaces";
import {BaseModel, EntityKind} from "./BaseModel";
import {CardComposite, ICard} from "./CardComposite";
import {GridValidRowModel} from "@mui/x-data-grid";

import {MAX_CARD_LENGTH} from "../consts";

export class Card extends BaseModel<Card, CardDTO> implements ICard, GridValidRowModel {
    private _status?: CardStatusDTO;
    private _origin?: CardOriginDTO;
    private _front: string;
    private _back: string;
    private _note?: string;
    private _archivedOn?: Date;
    private _composite?: CardComposite;
    
    constructor() {
        super();
        const now = new Date();
        this._back = "";
        this._front = "";
        this.id = uuidv4();
        this.userId = "";
    }
    
    toListItem(): ListItem {
        return {
            id: this.id,
            title: this._front,
            metadata1: this._back,
        }
    }
    
    init(): Card {
        return new Card();
    }
    
    TYPE: EntityKind = EntityKind.Card;
    
    public fromDTO(dto: CardDTO): void | IUIError {
        this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
        this.userId = convertFromDTOToID('userId', this.TYPE, dto.getUserId());
        this.createdOn = convertFromDTOToDate('createdOn', this.TYPE, dto.getCreatedon())!
        this.updatedOn = convertFromDTOToDate('updatedOn', this.TYPE, dto.getUpdatedon())!
        this.archivedOn = convertFromDTOToDate('lastLogin', this.TYPE, dto.getArchivedon(), true)
        this.origin = dto.getOrigin();
        this.status = dto.getStatus();
        this.front = dto.getFront();
        this.back = dto.getBack();
        
        let composite: CardComposite = new CardComposite();
        if (dto.getComposite() !== undefined) {
            let x = composite.fromDTO(dto.getComposite()!);
            if (x) {
                return x as IUIError;
            }
        }
        
        this.composite = composite;
    }
    
    prepareMedia(): void {
        if (this.composite) {
            this.composite.media = this.composite.media.map((media) => {
                media.cardId = this.id
                
                return media
            });
        }
    }
    
    public intoDTO(): IUIError | CardDTO {
        let dto = new CardDTO();
        
        this.prepareMedia()
        
        let compositeDTO = this.composite?.intoDTO();
        if (compositeDTO && isError(compositeDTO)) {
            return NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, `failed to convert card to DTO`);
        }
        
        dto.setId(new UUID_DTO().setValue(this.id));
        dto.setUserId(new UUID_DTO().setValue(this.userId));
        dto.setFront(this._front);
        dto.setBack(this._back);
        dto.setNote(this._note ? this._note : "")
        if (this._status) {
            dto.setStatus(this._status);
        }
        if (this._origin) {
            dto.setOrigin(this._origin);
        }
        if (this._composite) {
            dto.setComposite(compositeDTO as CardCompositeDTO);
        }
        if (this._archivedOn) {
            dto.setArchivedon(convertDateToTimestamp(this._archivedOn));
        }
        dto.setCreatedon(convertDateToTimestamp(this.createdOn));
        dto.setUpdatedon(convertDateToTimestamp(this.updatedOn));
        
        return dto;
    }
    
    public sanitize(): Card {
        this.front = this.front.trim();
        this.back = this.back.trim();
        this.note = this.note ? this.note.trim() : undefined;
        return this;
    }
    
    public customValidate(): Card | IUIError {
        if (this._front === "") {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "Card front cannot be empty");
        }
        if (this._back === "") {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "Card back cannot be empty");
        }
        if (this.front.length > MAX_CARD_LENGTH) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, `Card front cannot exceed ${MAX_CARD_LENGTH} characters`);
        }
        if (this.back.length > MAX_CARD_LENGTH) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, `Card front cannot exceed ${MAX_CARD_LENGTH} characters`);
        }
        
        if (this.composite) {
            let composite = this.composite.validate()
            
            if (isError(composite)) {
                return composite as IUIError
            }
            
            this.composite = composite as CardComposite
        }
        
        return this.sanitize();
    }
    
    clone(): Card {
        const clonedCard = new Card();
        clonedCard.id = this.id;
        clonedCard.userId = this.userId;
        clonedCard._status = this._status;
        clonedCard._origin = this._origin;
        clonedCard._front = this._front;
        clonedCard._back = this._back;
        clonedCard._note = this._note;
        clonedCard.createdOn = new Date(this.createdOn.getTime());
        clonedCard.updatedOn = new Date(this.updatedOn.getTime());
        clonedCard._archivedOn = this._archivedOn ? new Date(this._archivedOn.getTime()) : undefined;
        clonedCard._composite = this._composite ? this._composite.clone() : undefined;
        return clonedCard;
    }
    
    get status(): CardStatusDTO | undefined {
        return this._status;
    }
    
    set status(value: CardStatusDTO | undefined) {
        this._status = value;
    }
    
    get origin(): CardOriginDTO | undefined {
        return this._origin;
    }
    
    set origin(value: CardOriginDTO | undefined) {
        this._origin = value;
    }
    
    get front(): string {
        return this._front;
    }
    
    set front(value: string) {
        this._front = value;
    }
    
    get back(): string {
        return this._back;
    }
    
    set back(value: string) {
        this._back = value;
    }
    
    get note(): string | undefined {
        return this._note;
    }
    
    set note(value: string | undefined) {
        this._note = value;
    }
    
    get archivedOn(): Date | undefined {
        return this._archivedOn;
    }
    
    set archivedOn(value: Date | undefined) {
        this._archivedOn = value;
    }
    
    get composite(): CardComposite | undefined {
        return this._composite;
    }
    
    set composite(value: CardComposite | undefined) {
        this._composite = value;
    }
    
    to1LineString(): String {
        return this.front;
    }
    
    toDisplayable(): IDisplayItem {
        return {
            id: this.id,
            title: this.front,
            metadata1: this.back,
        } as IDisplayItem;
    }
}

export class CardStat implements IFromDTO<CardStatDTO> {
    private _id: string;
    private _reviewSM2CardStats: ReviewSM2CardStat[];
    private _reviewManualCardStats: ReviewManualCardStat[] = [];
    private _sm2ReviewAvgEasiness?: number;
    private _sm2ReviewAvgQuality?: number;
    private _sm2ReviewAvgInterval?: number;
    private _sm2ReviewAvgRepetitions?: number;
    private _manualReviewAvgEasiness?: number;
    private _manualReviewAvgQuality?: number;
    private _manualReviewAvgInterval?: number;
    private _manualReviewAvgRepetitions?: number;
    private _earliestReview?: Date;
    private _recentReview?: Date;
    
    constructor() {
        this._id = "";
        this._reviewSM2CardStats = [];
        this._sm2ReviewAvgEasiness = -1;
        this._sm2ReviewAvgQuality = -1;
        this._sm2ReviewAvgInterval = -1;
        this._sm2ReviewAvgRepetitions = -1;
    }
    
    fromDTO(dto: CardStatDTO): void | IUIError {
        if (dto.getId()) {
            if (dto.getId()!.getValue()) {
                this.id = dto.getId()!.getValue();
            } else {
                return NewUIError(
                    "fromDTO",
                    InternalErrorTypes.InvalidReviewCard,
                    `getId is empty '' - ${dto}"`
                );
            }
        } else {
            return NewUIError(
                "fromDTO",
                InternalErrorTypes.InvalidReviewCard,
                `getId is undefined '' - ${dto}"`
            );
        }
        
        this._reviewSM2CardStats = dto.getSm2statsList().map((x) => {
            let stat = new ReviewSM2CardStat();
            stat.fromDTO(x);
            return stat;
        });
        this.reviewManualCardStats = dto.getManualstatsList().map((x) => {
            let stat = new ReviewManualCardStat();
            stat.fromDTO(x);
            return stat;
        });
        this.sm2ReviewAvgQuality = dto.getSm2reviewavgquality();
        this._sm2ReviewAvgEasiness = dto.getSm2reviewavgeasiness();
        this._sm2ReviewAvgInterval = dto.getSm2reviewavginterval();
        this._sm2ReviewAvgRepetitions = dto.getSm2reviewavgrepetitions();
        if (dto.getEarliestreview()) {
            this.earliestReview = convertTimestampToDate(dto.getEarliestreview()!);
        }
        if (dto.getRecentreview()) {
            this.recentReview = convertTimestampToDate(dto.getRecentreview()!);
        }
    }
    
    get id(): string {
        return this._id;
    }
    
    set id(value: string) {
        this._id = value;
    }
    
    get sm2ReviewAvgEasiness(): number | undefined {
        return this._sm2ReviewAvgEasiness;
    }
    
    set sm2ReviewAvgEasiness(value: number | undefined) {
        this._sm2ReviewAvgEasiness = value;
    }
    
    get sm2ReviewAvgQuality(): number | undefined {
        return this._sm2ReviewAvgQuality;
    }
    
    get reviewManualCardStats(): ReviewManualCardStat[] {
        return this._reviewManualCardStats;
    }
    
    set reviewManualCardStats(value: ReviewManualCardStat[]) {
        this._reviewManualCardStats = value;
    }
    
    set sm2ReviewAvgQuality(value: number | undefined) {
        this._sm2ReviewAvgQuality = value;
    }
    
    get sm2ReviewAvgInterval(): number | undefined {
        return this._sm2ReviewAvgInterval;
    }
    
    set sm2ReviewAvgInterval(value: number | undefined) {
        this._sm2ReviewAvgInterval = value;
    }
    
    get sm2ReviewAvgRepetitions(): number | undefined {
        return this._sm2ReviewAvgRepetitions;
    }
    
    set sm2ReviewAvgRepetitions(value: number | undefined) {
        this._sm2ReviewAvgRepetitions = value;
    }
    
    get earliestReview(): Date | undefined {
        return this._earliestReview;
    }
    
    set earliestReview(value: Date | undefined) {
        this._earliestReview = value;
    }
    
    get recentReview(): Date | undefined {
        return this._recentReview;
    }
    
    set recentReview(value: Date | undefined) {
        this._recentReview = value;
    }
    
    get reviewSM2CardStats(): ReviewSM2CardStat[] {
        return this._reviewSM2CardStats;
    }
    
    set reviewSM2CardStats(value: ReviewSM2CardStat[]) {
        this._reviewSM2CardStats = value;
    }
    
    get manualReviewAvgEasiness(): number | undefined {
        return this._manualReviewAvgEasiness;
    }
    
    set manualReviewAvgEasiness(value: number | undefined) {
        this._manualReviewAvgEasiness = value;
    }
    
    get manualReviewAvgQuality(): number | undefined {
        return this._manualReviewAvgQuality;
    }
    
    set manualReviewAvgQuality(value: number | undefined) {
        this._manualReviewAvgQuality = value;
    }
    
    get manualReviewAvgInterval(): number | undefined {
        return this._manualReviewAvgInterval;
    }
    
    set manualReviewAvgInterval(value: number | undefined) {
        this._manualReviewAvgInterval = value;
    }
    
    get manualReviewAvgRepetitions(): number | undefined {
        return this._manualReviewAvgRepetitions;
    }
    
    set manualReviewAvgRepetitions(value: number | undefined) {
        this._manualReviewAvgRepetitions = value;
    }
}

/// This represents stats that a card may have after going through a few reviews. If no review has been done for a card
/// then the stats should be null/undefined
export interface CardStats {
    lastReview: Date;
    reviewCount: number;
    confidences: number[];
}

// Create a function that creates a Card object with dummy data, that takes in a userId and randomly generates
// short strings for the string data
export const createMockCard = (userId: string): Card => {
    let card = new Card();
    card.front = "front";
    card.back = "back";
    card.userId = userId;
    return card;
};
