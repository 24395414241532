import {ActionType, isError, IUIError, NewUIErrorV2} from "service/cartaError";
import {BaseModel, EntityKind} from "model/BaseModel";
import {UserAttributeDTO, UserDTO, UserSubscriptionDTO} from "proto/user_pb";
import {convertDateToTimestamp, convertFromDTOToDate, convertFromDTOToID, ListItem} from "utils/utils";
import {IDisplayItem} from "./interfaces";
import {UUID_DTO} from "proto/utils_pb";
import {IFromDTO, IIntoDTO} from "model/model";
import {StripePrice, StripeProduct} from "model/StripeProduct";
import {EnumSubscriptionProductDTO, StripePriceDto, StripeProductDto} from "proto/stripe_pb";

//export enum EnumSubscriptionProductDTO {
//   FREE = 0,
//   STANDARD = 1,
//   PRO = 2,
//   PREMIUM = 3,
// }
export function convertSubscriptionProductToString(status: EnumSubscriptionProductDTO): string {
    switch (status) {
        case EnumSubscriptionProductDTO.FREE:
            return "Free"
        case EnumSubscriptionProductDTO.STANDARD:
            return "Standard"
        case EnumSubscriptionProductDTO.PRO:
            return "Pro"
        case EnumSubscriptionProductDTO.PREMIUM:
            return "Premium"
        default:
            return "Unknown"
    }
}

// A user will onboard the first time they land on the website. They may choose to immediately cancel all
// onboarding in which case we log the `hasOnboarded` time and set all fields to false. If they choose to continue with
// onboarding we will show them the different onboarding flows when they land on those pages. Once we are done with theindividual
// onboarding we mark each of them as true, and once all are marked true we set `hasOnboarded` to the current time.
//
// A user at any time may choose to cancel the onboarding entirely, in which case we will set `hasOnboarded` to the current time.
export interface UserIntroTour {
    isComplete: boolean;
    showCardPage: boolean;
    showTopicPage: boolean;
    showDashboardPage: boolean;
    showResourcePage: boolean;
    showReviewPage: boolean
}

export interface IUserAttributes {
    introTour: UserIntroTour;
    themePreference: string;
    emailNotifications: boolean;
    languagePreference: string;
}

export class UserAttributes implements IFromDTO<UserAttributeDTO>, IIntoDTO<UserAttributeDTO> {
    introTour: UserIntroTour;
    themePreference: string = 'light'
    emailNotifications: boolean = true;
    languagePreference: string = 'en-US';

    constructor() {
        this.introTour = {
            isComplete: false,
            showCardPage: false,
            showTopicPage: false,
            showDashboardPage: false,
            showResourcePage: false,
            showReviewPage: false
        };
    }

    fromDTO(dto: UserAttributeDTO): void | IUIError {
        this.introTour = {
            isComplete: dto.getHascompletedintrotour(),
            showCardPage: false,
            showTopicPage: false,
            showDashboardPage: false,
            showResourcePage: false,
            showReviewPage: false,
        }
        this.themePreference = dto.getThemepreference()
        this.emailNotifications = dto.getEmailnotifications()
        this.languagePreference = dto.getLanguagepreference()
    }

    intoDTO(): UserAttributeDTO | IUIError {
        let dto = new UserAttributeDTO()
        dto.setHascompletedintrotour(this.introTour.isComplete)
        dto.setThemepreference(this.themePreference)
        dto.setEmailnotifications(this.emailNotifications)
        dto.setLanguagepreference(this.languagePreference)

        return dto
    }

    clone(): UserAttributes {
        let temp = Object.assign({}, this);
        let newAttr = new UserAttributes()

        newAttr.introTour = temp.introTour
        newAttr.themePreference = temp.themePreference
        newAttr.emailNotifications = temp.emailNotifications
        newAttr.languagePreference = temp.languagePreference

        return newAttr
    }

    fromJSON(json: IUserAttributes) {
        this.introTour = json.introTour
        this.themePreference = json.themePreference
        this.emailNotifications = json.emailNotifications
        this.languagePreference = json.languagePreference
    }
}

export class UserSubscription extends BaseModel<UserSubscription, UserSubscriptionDTO> {
    stripeSubscriptionId: string;
    status: number;
    products: StripeProduct[];
    price: StripePrice[];
    createdOn: Date;
    startDate: Date;
    endDate: Date;

    constructor() {
        super();
        this.stripeSubscriptionId = "";
        this.status = 0;
        this.products = [];
        this.price = [];
        this.createdOn = new Date();
        this.startDate = new Date();
        this.endDate = new Date();
    }

    fromDTO(dto: UserSubscriptionDTO): void | IUIError {
        let val = new UserSubscription()
        val.stripeSubscriptionId = dto.getStripeSubscriptionId()
        val.status = dto.getStatus()

        let products: StripeProduct[] = []
        dto.getProductsList().forEach((product) => {
            let temp = new StripeProduct()
            temp.fromDTO(product)
            products.push(temp)
        })

        let price: StripePrice[] = []
        dto.getPriceList().forEach((p) => {
            let temp = new StripePrice()
            temp.fromDTO(p)
            price.push(temp)
        })

        val.products = products
        val.price = price
        
        val.createdOn = convertFromDTOToDate('createdOn', this.TYPE, dto.getCreatedOn())!
        val.startDate = convertFromDTOToDate('startDate', this.TYPE, dto.getStartDate())!
        val.endDate = convertFromDTOToDate('endDate', this.TYPE, dto.getEndDate())!

        this.stripeSubscriptionId = val.stripeSubscriptionId
        this.status = val.status
        this.products = val.products
        this.price = val.price
        this.createdOn = val.createdOn
        this.startDate = val.startDate
        this.endDate = val.endDate
    }

    intoDTO(): UserSubscriptionDTO | IUIError {
        let dto = new UserSubscriptionDTO()

        let dto_products: StripeProductDto[] = []
        this.products.forEach((product) => {
            let temp = product.intoDTO()
            if (isError(temp)) {
                return NewUIErrorV2(ActionType.ConvertToDTO, this.TYPE, temp as IUIError)
            }
            dto_products.push(temp as StripeProductDto)
        })

        let dto_price: StripePriceDto[] = []
        this.price.forEach((p) => {
            let temp = p.intoDTO()
            if (isError(temp)) {
                return NewUIErrorV2(ActionType.ConvertToDTO, this.TYPE, temp as IUIError)
            }
            dto_price.push(temp as StripePriceDto)
        })

        dto.setStripeSubscriptionId(this.stripeSubscriptionId);
        dto.setStatus(this.status);
        dto.setProductsList(dto_products);
        dto.setPriceList(dto_price);
        dto.setCreatedOn(convertDateToTimestamp(this.createdOn));
        dto.setStartDate(convertDateToTimestamp(this.startDate));
        dto.setEndDate(convertDateToTimestamp(this.endDate));

        return dto
    }

    toJSON() {
        return {
            stripeSubscriptionId: this.stripeSubscriptionId,
            status: this.status,
            products: this.products,
            price: this.price,
            createdOn: this.createdOn,
            startDate: this.startDate,
            endDate: this.endDate
        }
    }

    TYPE: EntityKind = EntityKind.UserSubscription

    clone(): UserSubscription {
        let temp = Object.assign({}, this);
        let newSubscription = new UserSubscription()

        newSubscription.stripeSubscriptionId = temp.stripeSubscriptionId
        newSubscription.status = temp.status
        newSubscription.products = temp.products
        newSubscription.price = temp.price
        newSubscription.createdOn = temp.createdOn
        newSubscription.startDate = temp.startDate
        newSubscription.endDate = temp.endDate

        return newSubscription
    }

    customValidate(): UserSubscription | IUIError {
        if (this.stripeSubscriptionId === "") {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "stripeSubscriptionId is empty")
        }
        if (this.status === 0) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "status is empty")
        }
        if (this.products.length === 0) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "products is empty")
        }
        if (this.price.length === 0) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "price is empty")
        }

        return this
    }

    toListItem(): ListItem {
        return {
            id: this.stripeSubscriptionId, title: this.stripeSubscriptionId
        }
    }

    toDisplayable(): IDisplayItem {
        return {
            id: this.stripeSubscriptionId,
            title: this.stripeSubscriptionId,
            metadata1: this.status.toString(),
            metadata2: this.products.toString(),
            metadata3: this.price.toString()
        }
    }

    to1LineString(): String {
        return this.stripeSubscriptionId + " (" + this.status + ")"
    }

    sanitize(): UserSubscription {
        return this;
    }
}

export interface IUser {
    id: string;
    displayName: string;
    givenName: string;
    lastName: string;
    email: string;
    picture: string;
    createdOn: Date;
    lastLogin?: Date;
    attrs: IUserAttributes;
    updatedOn: Date;
    archivedOn?: Date;
}

export class User extends BaseModel<User, UserDTO> {
    id: string;
    displayName: string;
    givenName: string;
    lastName: string;
    email: string;
    picture: string;
    createdOn: Date;
    lastLogin?: Date;
    private _attrs: UserAttributes;
    updatedOn: Date;
    archivedOn?: Date;

    constructor() {
        super();
        this.id = "";
        this.displayName = "";
        this.givenName = "";
        this.lastName = "";
        this.email = "";
        this.picture = "";
        this._attrs = new UserAttributes();
        this.createdOn = new Date();
        this.updatedOn = new Date();
    }

    fromDTO(dto: UserDTO): void | IUIError {
        this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
        this.displayName = dto.getDisplayName()
        this.givenName = dto.getGivenName()
        this.lastName = dto.getLastName()
        this.email = dto.getEmail()
        this.picture = dto.getPicture()
        
        this.createdOn = convertFromDTOToDate('createdOn', this.TYPE, dto.getCreatedOn())!
        this.updatedOn = convertFromDTOToDate('updatedOn', this.TYPE, dto.getUpdatedOn())!
        this.lastLogin = convertFromDTOToDate('lastLogin', this.TYPE, dto.getLastLogin(), true)
        this.archivedOn = convertFromDTOToDate('archivedOn', this.TYPE, dto.getArchivedOn(), true)

        let attrs = new UserAttributes()
        if (dto.hasAttr()) {
            attrs.fromDTO(dto.getAttr()!)
        } else {
            throw NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, `UserDTO: ${this.id} does not have attributes`)
        }
        
        this._attrs = attrs
    }

    intoDTO(): UserDTO | IUIError {
        let dto = new UserDTO()

        dto.setId(new UUID_DTO().setValue(this.id));
        dto.setDisplayName(this.displayName)
        dto.setGivenName(this.givenName)
        dto.setLastName(this.lastName)
        dto.setEmail(this.email)
        dto.setPicture(this.picture)
        dto.setCreatedOn(convertDateToTimestamp(this.createdOn));
        dto.setUpdatedOn(convertDateToTimestamp(this.updatedOn));

        let attrs = this._attrs.intoDTO()
        if (isError(attrs)) {
            return NewUIErrorV2(ActionType.ConvertToDTO, this.TYPE, attrs as IUIError)
        }
        dto.setAttr(attrs as UserAttributeDTO)
        if (this.lastLogin) dto.setLastLogin(convertDateToTimestamp(this.lastLogin))

        return dto
    }

    toJSON() {
        return {
            id: this.id,
            displayName: this.displayName,
            givenName: this.givenName,
            lastName: this.lastName,
            email: this.email,
            picture: this.picture,
            attrs: this._attrs,
            createdOn: this.createdOn,
            updatedOn: this.updatedOn,
            lastLogin: this.lastLogin,
            archivedOn: this.archivedOn
        }
    }

    fromJSON(json: IUser) {
        this.id = json.id
        this.displayName = json.displayName
        this.givenName = json.givenName
        this.lastName = json.lastName
        this.email = json.email
        this.picture = json.picture
        this.createdOn = json.createdOn
        this.updatedOn = json.updatedOn
        this.lastLogin = json.lastLogin
        this.archivedOn = json.archivedOn

        let iattr: IUserAttributes = json.attrs
        let attrs = new UserAttributes()
        attrs.fromJSON(iattr)
        this._attrs = attrs
    }

    toDisplayable(): IDisplayItem {
        return {
            id: this.id,
            title: this.displayName,
            metadata1: this.email,
            imageUrl: this.picture
        }
    }

    to1LineString(): String {
        return this.displayName + " (" + this.email + ")"
    }

    TYPE: EntityKind = EntityKind.User

    clone(): User {
        let temp = Object.assign({}, this);
        let newUser = new User()

        newUser.id = temp.id
        newUser.displayName = temp.displayName
        newUser.givenName = temp.givenName
        newUser.lastName = temp.lastName
        newUser.email = temp.email
        newUser._attrs = temp.attrs
        newUser.picture = temp.picture
        newUser.createdOn = temp.createdOn
        newUser.updatedOn = temp.updatedOn
        newUser.lastLogin = temp.lastLogin
        newUser.archivedOn = temp.archivedOn

        return newUser
    }

    sanitize(): User {
        this.displayName = this.displayName.trim()
        this.email = this.email.trim()
        this.givenName = this.givenName.trim()
        this.lastName = this.lastName.trim()
        this.picture = this.picture.trim()

        return this
    }

    get attrs(): UserAttributes {
        return this._attrs;
    }

    set attrs(value: UserAttributes) {
        this._attrs = value;
    }

    customValidate(): User | IUIError {
        if (this.displayName === "") {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "displayName is empty")
        }
        if (this.email === "") {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "email is empty")
        }

        return this.sanitize()
    }

    toListItem(): ListItem {
        return {
            id: this.id, title: this.displayName, imageUrl: this.picture
        }
    }
}
