/**
 * @fileoverview gRPC-Web generated client stub for public
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: public.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var stripe_pb = require('./stripe_pb.js')
const proto = {};
proto.public = require('./public_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.public.PublicServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.public.PublicServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.public.GetProductAndPriceRequest,
 *   !proto.public.GetProductAndPriceResponse>}
 */
const methodDescriptor_PublicService_GetProductAndPrice = new grpc.web.MethodDescriptor(
  '/public.PublicService/GetProductAndPrice',
  grpc.web.MethodType.UNARY,
  proto.public.GetProductAndPriceRequest,
  proto.public.GetProductAndPriceResponse,
  /**
   * @param {!proto.public.GetProductAndPriceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.public.GetProductAndPriceResponse.deserializeBinary
);


/**
 * @param {!proto.public.GetProductAndPriceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.public.GetProductAndPriceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.public.GetProductAndPriceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.public.PublicServiceClient.prototype.getProductAndPrice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/public.PublicService/GetProductAndPrice',
      request,
      metadata || {},
      methodDescriptor_PublicService_GetProductAndPrice,
      callback);
};


/**
 * @param {!proto.public.GetProductAndPriceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.public.GetProductAndPriceResponse>}
 *     Promise that resolves to the response
 */
proto.public.PublicServicePromiseClient.prototype.getProductAndPrice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/public.PublicService/GetProductAndPrice',
      request,
      metadata || {},
      methodDescriptor_PublicService_GetProductAndPrice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.public.GetAllProductsRequest,
 *   !proto.public.GetAllProductsResponse>}
 */
const methodDescriptor_PublicService_GetAllProductsAndPrice = new grpc.web.MethodDescriptor(
  '/public.PublicService/GetAllProductsAndPrice',
  grpc.web.MethodType.UNARY,
  proto.public.GetAllProductsRequest,
  proto.public.GetAllProductsResponse,
  /**
   * @param {!proto.public.GetAllProductsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.public.GetAllProductsResponse.deserializeBinary
);


/**
 * @param {!proto.public.GetAllProductsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.public.GetAllProductsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.public.GetAllProductsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.public.PublicServiceClient.prototype.getAllProductsAndPrice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/public.PublicService/GetAllProductsAndPrice',
      request,
      metadata || {},
      methodDescriptor_PublicService_GetAllProductsAndPrice,
      callback);
};


/**
 * @param {!proto.public.GetAllProductsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.public.GetAllProductsResponse>}
 *     Promise that resolves to the response
 */
proto.public.PublicServicePromiseClient.prototype.getAllProductsAndPrice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/public.PublicService/GetAllProductsAndPrice',
      request,
      metadata || {},
      methodDescriptor_PublicService_GetAllProductsAndPrice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stripe.CreateCheckoutSessionRequest,
 *   !proto.stripe.CreateCheckoutSessionResponse>}
 */
const methodDescriptor_PublicService_PublicCreateCheckoutSession = new grpc.web.MethodDescriptor(
  '/public.PublicService/PublicCreateCheckoutSession',
  grpc.web.MethodType.UNARY,
  stripe_pb.CreateCheckoutSessionRequest,
  stripe_pb.CreateCheckoutSessionResponse,
  /**
   * @param {!proto.stripe.CreateCheckoutSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  stripe_pb.CreateCheckoutSessionResponse.deserializeBinary
);


/**
 * @param {!proto.stripe.CreateCheckoutSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stripe.CreateCheckoutSessionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stripe.CreateCheckoutSessionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.public.PublicServiceClient.prototype.publicCreateCheckoutSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/public.PublicService/PublicCreateCheckoutSession',
      request,
      metadata || {},
      methodDescriptor_PublicService_PublicCreateCheckoutSession,
      callback);
};


/**
 * @param {!proto.stripe.CreateCheckoutSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stripe.CreateCheckoutSessionResponse>}
 *     Promise that resolves to the response
 */
proto.public.PublicServicePromiseClient.prototype.publicCreateCheckoutSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/public.PublicService/PublicCreateCheckoutSession',
      request,
      metadata || {},
      methodDescriptor_PublicService_PublicCreateCheckoutSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stripe.CreatePortalSessionRequest,
 *   !proto.stripe.CreatePortalSessionResponse>}
 */
const methodDescriptor_PublicService_PublicCreatePortalSession = new grpc.web.MethodDescriptor(
  '/public.PublicService/PublicCreatePortalSession',
  grpc.web.MethodType.UNARY,
  stripe_pb.CreatePortalSessionRequest,
  stripe_pb.CreatePortalSessionResponse,
  /**
   * @param {!proto.stripe.CreatePortalSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  stripe_pb.CreatePortalSessionResponse.deserializeBinary
);


/**
 * @param {!proto.stripe.CreatePortalSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stripe.CreatePortalSessionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stripe.CreatePortalSessionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.public.PublicServiceClient.prototype.publicCreatePortalSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/public.PublicService/PublicCreatePortalSession',
      request,
      metadata || {},
      methodDescriptor_PublicService_PublicCreatePortalSession,
      callback);
};


/**
 * @param {!proto.stripe.CreatePortalSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stripe.CreatePortalSessionResponse>}
 *     Promise that resolves to the response
 */
proto.public.PublicServicePromiseClient.prototype.publicCreatePortalSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/public.PublicService/PublicCreatePortalSession',
      request,
      metadata || {},
      methodDescriptor_PublicService_PublicCreatePortalSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.public.ContactUsRequest,
 *   !proto.public.ContactUsResponse>}
 */
const methodDescriptor_PublicService_ContactUs = new grpc.web.MethodDescriptor(
  '/public.PublicService/ContactUs',
  grpc.web.MethodType.UNARY,
  proto.public.ContactUsRequest,
  proto.public.ContactUsResponse,
  /**
   * @param {!proto.public.ContactUsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.public.ContactUsResponse.deserializeBinary
);


/**
 * @param {!proto.public.ContactUsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.public.ContactUsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.public.ContactUsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.public.PublicServiceClient.prototype.contactUs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/public.PublicService/ContactUs',
      request,
      metadata || {},
      methodDescriptor_PublicService_ContactUs,
      callback);
};


/**
 * @param {!proto.public.ContactUsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.public.ContactUsResponse>}
 *     Promise that resolves to the response
 */
proto.public.PublicServicePromiseClient.prototype.contactUs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/public.PublicService/ContactUs',
      request,
      metadata || {},
      methodDescriptor_PublicService_ContactUs);
};


module.exports = proto.public;

