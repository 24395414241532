import {BaseModel, EntityKind} from "../BaseModel";
import {IndustryIdentifierDTO, ResourceMetadataDTO} from "proto/resource_pb";
import {IDisplayItem} from "../interfaces";
import {ActionType, isError, IUIError, NewUIErrorV2} from "service/cartaError";
import {
    convertDateToTimestamp,
    convertFromDTOToDate,
    convertFromDTOToID,
    convertTimestampToDate,
    ListItem,
    NewUUID
} from "utils/utils";
import {IFromDTO, IIntoDTO, IModel} from "../model";
import {UUID_DTO} from "proto/utils_pb";

export interface IResourceMetadata extends IModel {
    resourceId: string;

    language?: string;
    doi?: string;
    edition?: string;
    version?: string;
    format?: string;
    smallThumbnail?: string;
    categories?: string[];
    publisher?: string;
    publishedOn?: Date;
    conference?: string;
    volume?: string;
    textSnippet?: string;
    industryIdentifiers?: IndustryIdentifier[];
    pageNumbers?: string;
    pageCount?: number;
    accessedOn?: Date;
    subtitle?: string;
    description?: string;
    isDefault: boolean;
}

export class ResourceMetadata extends BaseModel<ResourceMetadata, ResourceMetadataDTO> {
    private _language?: string;
    private _resourceId: string;
    private _doi?: string;
    private _edition?: string;
    private _version?: string;
    private _format?: string;
    private _smallThumbnail?: string;
    private _categories?: string[];
    private _publisher?: string;
    private _publishedOn?: Date;
    private _conference?: string;
    private _subtitle?: string;
    private _description?: string;
    private _volume?: string;
    private _textSnippet?: string;
    private _industryIdentifiers?: IndustryIdentifier[];
    private _page_numbers?: string;
    private _pageCount?: number;
    private _accessedOn?: Date;
    // We get this from the server, we never set it
    private _is_default: boolean = false;

    constructor() {
        super()
        this._industryIdentifiers = [];
        this._categories = [];
        this._resourceId = "";
    }

    toListItem(): ListItem {
        return {
            id: this.doi ? this.doi : "",
            title: this._textSnippet ? this._textSnippet : "",
            metadata1: this.language ? this.language : "",
        }
    }

    toDisplayable(): IDisplayItem {
        throw new Error("Method not implemented.");
    }

    to1LineString(): String {
        throw new Error("Method not implemented.");
    }

    static fromParts(userId: string, resourceId: string): ResourceMetadata {
        let resourceMetadata = new ResourceMetadata();
        resourceMetadata.id = NewUUID();
        resourceMetadata.resourceId = resourceId;
        resourceMetadata.userId = userId;
        return resourceMetadata;
    }

    toJSON(): IResourceMetadata {
        return {
            resourceId: this.resourceId,
            id: this.id,
            userId: this.userId,
            createdOn: this.createdOn,
            updatedOn: this.updatedOn,
            language: this.language,
            doi: this.doi,
            edition: this.edition,
            version: this.version,
            format: this.format,
            smallThumbnail: this.smallThumbnail,
            categories: this.categories,
            publisher: this.publisher,
            publishedOn: this.publishedOn,
            conference: this.conference,
            volume: this.volume,
            textSnippet: this.textSnippet,
            industryIdentifiers: this.industryIdentifiers,
            pageNumbers: this.page_numbers,
            pageCount: this.pageCount,
            accessedOn: this.accessedOn,
            subtitle: this.subtitle,
            description: this.description,
            isDefault: this.is_default
        };
    }

    static fromJSON(temp: IResourceMetadata): ResourceMetadata {
        let resourceMetadata = new ResourceMetadata();

        console.log("temp: ", temp)

        resourceMetadata.id = temp.id;
        resourceMetadata.userId = temp.userId;
        resourceMetadata.createdOn = new Date(temp.createdOn);
        resourceMetadata.updatedOn = new Date(temp.updatedOn);

        resourceMetadata.accessedOn = temp.accessedOn ? new Date(temp.accessedOn) : undefined;
        resourceMetadata.categories = temp.categories;
        resourceMetadata.conference = temp.conference;
        resourceMetadata.doi = temp.doi;
        resourceMetadata.edition = temp.edition;
        resourceMetadata.format = temp.format;
        resourceMetadata.industryIdentifiers = temp.industryIdentifiers;
        resourceMetadata.language = temp.language;
        resourceMetadata.pageCount = temp.pageCount;
        resourceMetadata.page_numbers = temp.pageNumbers;
        resourceMetadata.publishedOn = temp.publishedOn ? new Date(temp.publishedOn) : undefined;
        resourceMetadata.publisher = temp.publisher;
        resourceMetadata.smallThumbnail = temp.smallThumbnail;
        resourceMetadata.textSnippet = temp.textSnippet;
        resourceMetadata.version = temp.version;
        resourceMetadata.volume = temp.volume;
        resourceMetadata.description = temp.description;
        resourceMetadata.subtitle = temp.subtitle;
        resourceMetadata.resourceId = temp.resourceId;

        return resourceMetadata;
    }

    private _TYPE: EntityKind = EntityKind.ResourceMetadata

    fromDTO(dto: ResourceMetadataDTO): void | IUIError {
        this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
        this.userId = convertFromDTOToID('userId', this.TYPE, dto.getUserId());
        this.publishedOn = convertFromDTOToDate('publishedOn', this.TYPE, dto.getPublishedOn(), true);
        this.accessedOn = convertFromDTOToDate('publishedOn', this.TYPE, dto.getAccessedOn(), true);
        this.resourceId = convertFromDTOToID('resourceId', this.TYPE, dto.getResourceId());
        this.language = dto.getLanguage();
        this.doi = dto.getDoi();
        this.edition = dto.getEdition();
        this.version = dto.getVersion();
        this.format = dto.getFormat();
        this.smallThumbnail = dto.getSmallThumbnail();
        this.categories = dto.getCategoriesList();
        this.publisher = dto.getPublisher();
        this.conference = dto.getConference();
        this.volume = dto.getVolume();
        this.subtitle = dto.getSubtitle();
        this.description = dto.getDescription();
        this.textSnippet = dto.getTextSnippet();
        this.page_numbers = dto.getPageNumbers();
        this.pageCount = dto.getPageCount();
        this.is_default = dto.getIsdefault(); // We get this from the server,

        let identifiers: IndustryIdentifier[] = []
        if (dto.getIndustryIdentifiersList() !== undefined) {
            dto.getIndustryIdentifiersList().forEach((x) => {

                let type = x.getTypes() as IndustryIdentifierTypes;

                let y = new IndustryIdentifier(type, x.getIdentifier())
                identifiers.push(y)
            })
        }

        this.industryIdentifiers = identifiers;
    }

    intoDTO(): IUIError | ResourceMetadataDTO {
        let published_on;
        if (this.publishedOn !== undefined) {
            published_on = convertDateToTimestamp(this.publishedOn);
        }

        let accessed_on;
        if (this.accessedOn !== undefined) {
            accessed_on = convertDateToTimestamp(this.accessedOn);
        }

        let identifiers: IndustryIdentifierDTO[] = []
        if (this.industryIdentifiers !== undefined) {
            this.industryIdentifiers.forEach((x) => {
                let y = x.intoDTO()
                if (isError(y)) {
                    return y
                }

                identifiers.push(y as IndustryIdentifierDTO)
            })
        }

        let dto = new ResourceMetadataDTO();
        dto.setLanguage(this.language ? this.language : '');
        dto.setDoi(this.doi ? this.doi : '');
        dto.setEdition(this.edition ? this.edition : '');
        dto.setVersion(this.version ? this.version : '');
        dto.setFormat(this.format ? this.format : '');
        dto.setSmallThumbnail(this.smallThumbnail ? this.smallThumbnail : '');
        dto.setCategoriesList(this.categories ? this.categories : []);
        dto.setPublisher(this.publisher ? this.publisher : '');
        dto.setPublishedOn(published_on);
        dto.setConference(this.conference ? this.conference : '');
        dto.setVolume(this.volume ? this.volume : '');
        dto.setTextSnippet(this.textSnippet ? this.textSnippet : '');
        dto.setIndustryIdentifiersList(identifiers);
        dto.setPageNumbers(this.page_numbers ? this.page_numbers : '');
        dto.setPageCount(this.pageCount ? this.pageCount : 0);
        dto.setAccessedOn(accessed_on);
        dto.setSubtitle(this.subtitle ? this.subtitle : '');
        dto.setDescription(this.description ? this.description : '');
        dto.setUserId(new UUID_DTO().setValue(this.userId));
        dto.setId(new UUID_DTO().setValue(this.id));
        dto.setResourceId(new UUID_DTO().setValue(this.resourceId));
        dto.setCreatedon(convertDateToTimestamp(this.createdOn));
        return dto;
    }

    clone(): ResourceMetadata {
        let resourceMetadata = new ResourceMetadata();

        resourceMetadata.id = this.id;
        resourceMetadata.userId = this.userId;
        resourceMetadata.createdOn = this.createdOn;
        resourceMetadata.updatedOn = this.updatedOn;
        resourceMetadata.resourceId = this.resourceId;
        resourceMetadata.accessedOn = this.accessedOn;
        resourceMetadata.categories = this.categories;
        resourceMetadata.conference = this.conference;
        resourceMetadata.doi = this.doi;
        resourceMetadata.edition = this.edition;
        resourceMetadata.format = this.format;
        resourceMetadata.industryIdentifiers = this.industryIdentifiers ? [...this.industryIdentifiers] : undefined;
        resourceMetadata.language = this.language;
        resourceMetadata.pageCount = this.pageCount;
        resourceMetadata.page_numbers = this.page_numbers;
        resourceMetadata.publishedOn = this.publishedOn;
        resourceMetadata.publisher = this.publisher;
        resourceMetadata.smallThumbnail = this.smallThumbnail;
        resourceMetadata.textSnippet = this.textSnippet;
        resourceMetadata.version = this.version;
        resourceMetadata.volume = this.volume;
        resourceMetadata.description = this.description;
        resourceMetadata.subtitle = this.subtitle;

        return resourceMetadata;
    }

    sanitize(): ResourceMetadata {
        this.language = this.language?.trim();
        this.doi = this.doi?.trim();
        this.edition = this.edition?.trim();
        this.version = this.version?.trim();
        this.format = this.format?.trim();
        this.smallThumbnail = this.smallThumbnail?.trim();
        this.categories = this.categories?.map((x) => x.trim());
        this.publisher = this.publisher?.trim();
        this.conference = this.conference?.trim();
        this.volume = this.volume?.trim();
        this.textSnippet = this.textSnippet?.trim();
        this.page_numbers = this.page_numbers?.trim();
        this.subtitle = this.subtitle?.trim();
        this.description = this.description?.trim();

        return this;
    }

    customValidate(): IUIError | ResourceMetadata {
        // I want the validation to ensure the fields if not empty are between 2 and 250 characters
        // and dont contain characters that may be harmful to the system. Use the NewUIErrorV2 function to return errors
        // and populate the message param with useful information.

        if (this.doi !== undefined) {
            if (this.doi.length < 2 || this.doi.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "DOI is not between 2 and 250 characters"
                )
            }
        }
        if (this.language !== undefined) {
            if (this.language.length < 2 || this.language.length > 25) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Language is not between 2 and 25 characters"
                )
            }
        }
        if (this.edition !== undefined) {
            if (this.edition.length < 2 || this.edition.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Edition is not between 2 and 250 characters"
                )
            }
        }
        if (this.version !== undefined) {
            if (this.version.length < 2 || this.version.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Version is not between 2 and 250 characters"
                )
            }
        }
        if (this.format !== undefined) {
            if (this.format.length < 2 || this.format.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Format is not between 2 and 250 characters"
                )
            }
        }
        if (this.smallThumbnail !== undefined) {
            if (this.smallThumbnail.length < 2 || this.smallThumbnail.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Small Thumbnail is not between 2 and 250 characters"
                )
            }
        }
        if (this.publisher !== undefined) {
            if (this.publisher.length < 2 || this.publisher.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Publisher is not between 2 and 250 characters"
                )
            }
        }
        if (this.conference !== undefined) {
            if (this.conference.length < 2 || this.conference.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Conference is not between 2 and 250 characters"
                )
            }
        }
        if (this.volume !== undefined) {
            if (this.volume.length < 2 || this.volume.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Volume is not between 2 and 250 characters"
                )
            }
        }
        if (this.textSnippet !== undefined) {
            if (this.textSnippet.length < 2 || this.textSnippet.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Text Snippet is not between 2 and 250 characters"
                )
            }
        }
        if (this.page_numbers !== undefined) {
            if (this.page_numbers.length < 2 || this.page_numbers.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Page Numbers is not between 2 and 250 characters"
                )
            }
        }
        if (this.pageCount !== undefined) {
            if (this.pageCount < 0 || this.pageCount > 10000) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Page Count is not between 0 and 10000"
                )
            }
        }

        return this.sanitize()
    }

    get TYPE(): EntityKind {
        return this._TYPE;
    }

    set TYPE(value: EntityKind) {
        this._TYPE = value;
    }

    get language(): string | undefined {
        return this._language;
    }

    set language(value: string | undefined) {
        this._language = value;
    }

    get doi(): string | undefined {
        return this._doi;
    }

    set doi(value: string | undefined) {
        this._doi = value;
    }

    get edition(): string | undefined {
        return this._edition;
    }

    set edition(value: string | undefined) {
        this._edition = value;
    }

    get version(): string | undefined {
        return this._version;
    }

    set version(value: string | undefined) {
        this._version = value;
    }

    get format(): string | undefined {
        return this._format;
    }

    set format(value: string | undefined) {
        this._format = value;
    }

    get smallThumbnail(): string | undefined {
        return this._smallThumbnail;
    }

    set smallThumbnail(value: string | undefined) {
        this._smallThumbnail = value;
    }

    get categories(): string[] | undefined {
        return this._categories;
    }

    set categories(value: string[] | undefined) {
        this._categories = value;
    }

    get publisher(): string | undefined {
        return this._publisher;
    }

    set publisher(value: string | undefined) {
        this._publisher = value;
    }

    get publishedOn(): Date | undefined {
        return this._publishedOn;
    }

    set publishedOn(value: Date | undefined) {
        this._publishedOn = value;
    }

    get conference(): string | undefined {
        return this._conference;
    }

    set conference(value: string | undefined) {
        this._conference = value;
    }

    get volume(): string | undefined {
        return this._volume;
    }

    set volume(value: string | undefined) {
        this._volume = value;
    }

    get textSnippet(): string | undefined {
        return this._textSnippet;
    }

    set textSnippet(value: string | undefined) {
        this._textSnippet = value;
    }

    get industryIdentifiers(): IndustryIdentifier[] | undefined {
        return this._industryIdentifiers;
    }

    set industryIdentifiers(value: IndustryIdentifier[] | undefined) {
        this._industryIdentifiers = value;
    }

    get page_numbers(): string | undefined {
        return this._page_numbers;
    }

    set page_numbers(value: string | undefined) {
        this._page_numbers = value;
    }

    get pageCount(): number | undefined {
        return this._pageCount;
    }

    set pageCount(value: number | undefined) {
        this._pageCount = value;
    }

    get accessedOn(): Date | undefined {
        return this._accessedOn;
    }

    set accessedOn(value: Date | undefined) {
        this._accessedOn = value;
    }

    get subtitle(): string | undefined {
        return this._subtitle;
    }

    set subtitle(value: string | undefined) {
        this._subtitle = value;
    }

    get description(): string | undefined {
        return this._description;
    }

    set description(value: string | undefined) {
        this._description = value;
    }

    get resourceId(): string {
        return this._resourceId;
    }

    set resourceId(value: string) {
        this._resourceId = value;
    }

    get is_default(): boolean {
        return this._is_default;
    }

    set is_default(value: boolean) {
        this._is_default = value;
    }
}

export type IndustryIdentifierTypes = "isbn10" | "isbn13" | "issn" | "other";

export class IndustryIdentifier implements IIntoDTO<IndustryIdentifierDTO>, IFromDTO<IndustryIdentifierDTO> {
    type: IndustryIdentifierTypes;
    identifier: string;

    constructor(type: IndustryIdentifierTypes, identifier: string) {
        this.type = type;
        this.identifier = identifier;
    }

    fromDTO(t: IndustryIdentifierDTO): void | IUIError {
        this.type = t.getTypes() as IndustryIdentifierTypes;
        this.identifier = t.getIdentifier();
    }

    intoDTO(): IndustryIdentifierDTO | IUIError {
        let dto = new IndustryIdentifierDTO();
        dto.setTypes(this.type);
        dto.setIdentifier(this.identifier);
        return dto;
    }

    clone(): IndustryIdentifier {
        return new IndustryIdentifier(this.type, this.identifier);
    }
}