/**
 * @fileoverview gRPC-Web generated client stub for stripe
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: stripe.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var utils_pb = require('./utils_pb.js')
const proto = {};
proto.stripe = require('./stripe_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.stripe.StripeServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.stripe.StripeServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stripe.CreateCheckoutSessionRequest,
 *   !proto.stripe.CreateCheckoutSessionResponse>}
 */
const methodDescriptor_StripeService_CreateCheckoutSession = new grpc.web.MethodDescriptor(
  '/stripe.StripeService/CreateCheckoutSession',
  grpc.web.MethodType.UNARY,
  proto.stripe.CreateCheckoutSessionRequest,
  proto.stripe.CreateCheckoutSessionResponse,
  /**
   * @param {!proto.stripe.CreateCheckoutSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stripe.CreateCheckoutSessionResponse.deserializeBinary
);


/**
 * @param {!proto.stripe.CreateCheckoutSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stripe.CreateCheckoutSessionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stripe.CreateCheckoutSessionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stripe.StripeServiceClient.prototype.createCheckoutSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stripe.StripeService/CreateCheckoutSession',
      request,
      metadata || {},
      methodDescriptor_StripeService_CreateCheckoutSession,
      callback);
};


/**
 * @param {!proto.stripe.CreateCheckoutSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stripe.CreateCheckoutSessionResponse>}
 *     Promise that resolves to the response
 */
proto.stripe.StripeServicePromiseClient.prototype.createCheckoutSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stripe.StripeService/CreateCheckoutSession',
      request,
      metadata || {},
      methodDescriptor_StripeService_CreateCheckoutSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stripe.CreatePortalSessionRequest,
 *   !proto.stripe.CreatePortalSessionResponse>}
 */
const methodDescriptor_StripeService_CreatePortalSession = new grpc.web.MethodDescriptor(
  '/stripe.StripeService/CreatePortalSession',
  grpc.web.MethodType.UNARY,
  proto.stripe.CreatePortalSessionRequest,
  proto.stripe.CreatePortalSessionResponse,
  /**
   * @param {!proto.stripe.CreatePortalSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stripe.CreatePortalSessionResponse.deserializeBinary
);


/**
 * @param {!proto.stripe.CreatePortalSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stripe.CreatePortalSessionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stripe.CreatePortalSessionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stripe.StripeServiceClient.prototype.createPortalSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stripe.StripeService/CreatePortalSession',
      request,
      metadata || {},
      methodDescriptor_StripeService_CreatePortalSession,
      callback);
};


/**
 * @param {!proto.stripe.CreatePortalSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stripe.CreatePortalSessionResponse>}
 *     Promise that resolves to the response
 */
proto.stripe.StripeServicePromiseClient.prototype.createPortalSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stripe.StripeService/CreatePortalSession',
      request,
      metadata || {},
      methodDescriptor_StripeService_CreatePortalSession);
};


module.exports = proto.stripe;

