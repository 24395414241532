import {IFromDTO, IIntoDTO} from "./model";
import {JWTTokenDTO} from "../proto/auth_pb";
import {IUIError} from "../service/cartaError";
import {convertDateToTimestamp, convertTimestampToDate} from "../utils/utils";
import { jwtDecode } from "jwt-decode";
import {CredentialResponse} from "@react-oauth/google";
import {User} from "model/user";

import firebase from 'firebase/compat/app';
import {
	GenericFederatedProfile,
	GoogleOAuthToken,
	IGoogleAdditionalUserInfo,
	IGoogleOAuthCredential
} from "../service/AuthService";

export interface AuthObj {
	userId: string,
	jwt: JWTToken,
	user: User
}

export interface IProfile {
	profileObj: GenericFederatedProfile;
}

export interface IGoogleOAuthTokenResponse extends IProfile{
	tokenObj: GoogleOAuthToken;
}

export  const convertFirebaseGoogleSignInResponseToIGoogleOAuthTokenResponse = (response: any): IGoogleOAuthTokenResponse => {
	if (response.additionalUserInfo === undefined) {
		throw new Error("Google OAuth response is missing additionalUserInfo")
	}
	if (response.additionalUserInfo?.profile === undefined) {
		throw new Error("Google OAuth response is missing profiles")
	}
	if (!response.credential) {
		throw new Error("Google OAuth response is missing credentials")
	}
	if (response.credential.providerId === undefined) {
		throw new Error("Google OAuth response is missing providerId")
	}

	let additionalInfo: IGoogleAdditionalUserInfo = response.additionalUserInfo
	let credential: IGoogleOAuthCredential = response.credential

	let profile = additionalInfo.profile;
	if (profile === null) {
		throw new Error("Google OAuth response is missing profiles")
	}

	const token =  jwtDecode(response.credential.idToken) as GoogleDecodedJWT

	let googleProfile = new GenericFederatedProfile()
	googleProfile.providerId = profile.id
	googleProfile.email = profile.email
	googleProfile.familyName = profile.family_name
	googleProfile.givenName = profile.given_name
	googleProfile.imageUrl = profile.picture
	googleProfile.name = profile.name
	googleProfile.locale = profile.locale
	googleProfile.isVerified = profile.verified_email
	googleProfile.isNewUser = additionalInfo.isNewUser

	let oauthToken = new GoogleOAuthToken()
	oauthToken.accessToken = credential.accessToken
	oauthToken.expiresAt = token.exp
	oauthToken.expiresIn = token.exp - token.iat
	oauthToken.firstIssuedAt = token.iat
	oauthToken.idToken = credential.idToken
	oauthToken.tokenType = "Bearer"
	oauthToken.refreshToken = response.user.refreshToken

	return {
		profileObj: googleProfile,
		tokenObj: oauthToken
	}
}

export interface GoogleDecodedJWT {
	iss: string;
	sub: string;
	aud: string;
	azp: string;
	exp: number;
	iat: number;
	at_hash: string;
	email: string;
	email_verified: boolean;
	locale: string;
}

export class JWTToken implements IFromDTO<JWTTokenDTO>, IIntoDTO<JWTTokenDTO>{
	token: string;
	issueDate?: Date;

	expiryDate?: Date;

	constructor() {
		this.token = "";
	}

	fromDTO(t: JWTTokenDTO): void | IUIError {
		this.token = t.getToken();
		if (t.getIssueDate()) {
			this.issueDate = convertTimestampToDate(t.getIssueDate()!)
		}
		if (t.getExpiryDate()) {
			this.expiryDate = convertTimestampToDate(t.getExpiryDate()!)
		}

        return
	}

	intoDTO(): IUIError | JWTTokenDTO {
		let dto = new JWTTokenDTO();
		dto.setToken(this.token)
		this.issueDate ? dto.setIssueDate(convertDateToTimestamp(this.issueDate!)) : dto.setIssueDate(undefined)
		this.expiryDate ? dto.setExpiryDate(convertDateToTimestamp(this.expiryDate!)) : dto.setIssueDate(undefined)
		dto.setToken(this.token)

		return dto
	}

}