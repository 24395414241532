import {ListOptionsRequestDTO, ListOptionsResponseDTO, ProgressStateEnumDTO, UUID_DTO,} from "../proto/utils_pb";
import {ReviewManual, ReviewManualStat} from "../model/ReviewManual";
import {ReviewManualServicePromiseClient} from "../proto/reviewManual_grpc_web_pb";
import {
    CompleteManualReviewRequest,
    CreateReviewManualRequest,
    DeleteReviewManualRequest,
    GetReviewManualRequest,
    GetReviewManualStatsRequest,
    ListCardsToReviewByConfigRequest,
    ListManualCardsRequest,
    ListReviewManualByIdsRequest,
    ListReviewManualRequest,
    ReviewManualCardDTO,
    ReviewManualDTO,
    ReviewManualOptionsDTO,
    SaveManualReviewSessionCardRequest,
    UpdateManualReviewRequest,
} from "proto/reviewManual_pb";
import {IsUUIDValid} from "utils/utils";
import {ReviewManualCard,} from "model/ReviewManualCard";
import {
    DTOCreatorRequestType,
    DTOCreatorResponseType,
    EntityKind,
    IGRPCReviewService,
    IGRPCService,
    ListResponse,
} from "model/BaseModel";
import grpcWeb from "grpc-web";
import {ReviewManualStatDTO} from "proto/stats_pb";
import {ReviewBaseService} from "./ReviewBaseService";
import {ActionType, InternalErrorTypes, isError, IUIError, NewUIError, NewUIErrorV2,} from "./cartaError";
import {Err, Ok, Result} from "utils/result";
import {ReviewSM2DTO} from "proto/reviewSM2_pb";
import {CARTA_PROXY_URL} from "consts";

const reviewManualClient = new ReviewManualServicePromiseClient(
    CARTA_PROXY_URL!,
    null,
    {withCredentials: true}
);

export interface CompleteReviewManual {
    review: ReviewManual;
    stats: ReviewManualStat;
}

export class ReviewManualService extends ReviewBaseService<
    ReviewManual,
    ReviewManualDTO,
    ReviewManualCard,
    ReviewManualCardDTO,
    ReviewManualStat,
    ReviewManualStatDTO,
    ReviewManualOptionsDTO,
    ReviewManualGRPC
> {
    constructor() {
        super(
            new ReviewManualGRPC(),
            ReviewManual,
            ReviewManualCard,
            ReviewManualStat
        );
    }
    
    ResumeReview = async (
        review: ReviewManual
    ): Promise<
        [review: ReviewManual, reviewCards: ReviewManualCard[]] | IUIError
    > => {
        let obj: [review: ReviewManual, reviewCards: ReviewManualCard[]] = [
            review,
            [],
        ];
        
        const now = new Date();
        review.updatedOn = now;
        review.progressState = ProgressStateEnumDTO.IN_PROGRESS;
        
        // TODO: I need to catch these errors and return them
        try {
            const updateReview = await this.Update(review);
            
            if (updateReview.ok) {
                obj[0] = updateReview.value;
            } else {
                return NewUIError(
                    "ResumeReview",
                    InternalErrorTypes.ResumeReviewSM2,
                    `failed to update review: ${review.id} to resume - ${updateReview.error}`
                );
            }
        } catch (err) {
            return NewUIError(
                "ResumeReview",
                InternalErrorTypes.ResumeReviewSM2,
                `failed to update review: ${review.id} to resume - ${err}`
            );
        }
        
        try {
            const fetchCards = await this.ListCards(review.id);
            
            if (fetchCards.ok) {
                obj[1] = fetchCards.value;
            } else {
                return NewUIError(
                    "ResumeReview",
                    InternalErrorTypes.ResumeReviewSM2,
                    `failed to fetch cards for review: ${review.id} to resume - ${fetchCards.error}`
                );
            }
        } catch (e) {
            return NewUIError(
                "ResumeReview",
                InternalErrorTypes.ResumeReviewSM2,
                `failed to fetch cards for review: ${review.id} to resume - ${e}`
            );
        }
        
        return obj;
    };
    
    async SaveCardWithNext(
        reviewId: string,
        next_card_id: string,
        card: ReviewManualCard
    ): Promise<Result<ReviewManualCard, IUIError>> {
        const actionType = ActionType.SaveReviewCards;
        
        if (!IsUUIDValid(reviewId)) {
            return Err(
                NewUIErrorV2(
                    actionType,
                    EntityKind.ReviewCard,
                    undefined,
                    `invalid reviewId: ${reviewId}`
                )
            );
        }
        
        if (!IsUUIDValid(next_card_id)) {
            return Err(
                NewUIErrorV2(
                    actionType,
                    EntityKind.ReviewCard,
                    undefined,
                    `invalid next_card id: ${next_card_id}`
                )
            );
        }
        
        card.cardId = card.composite.card.id;
        
        let validation = card.customValidate();
        
        
        
        if (isError(validation)) {
            return Err(
                NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ReviewManualCard,
                    `failed to validate: ${validation}`
                )
            );
        }
        
        const dto = card.intoDTO();
        
        if (isError(dto)) {
            return Err(
                NewUIErrorV2(
                    ActionType.ConvertToDTO,
                    EntityKind.ReviewManualCard,
                    `failed to convert: ${dto}`
                )
            );
        }
        
        const req: DTOCreatorRequestType = this.client.setupSaveCardWithNextReq(
            reviewId,
            next_card_id,
            dto as ReviewManualCardDTO
        );
        
        try {
            const response: ReviewManualCardDTO | undefined =
                await this.client.saveCard(req);
            
            
            if (response === undefined) {
                return Err(
                    NewUIErrorV2(
                        actionType,
                        EntityKind.ReviewManualCard,
                        undefined,
                        `list entity response is undefined`
                    )
                );
            }
            
            let newM = new ReviewManualCard();
            const err = newM.fromDTO(response as ReviewManualCardDTO);
            if (err) {
                return Err(
                    NewUIErrorV2(
                        ActionType.ConvertToDTO,
                        newM.TYPE,
                        err as IUIError,
                        `failed to convert returned entity: ${newM}`
                    )
                );
            }
            
            
            return Ok(newM);
        } catch (err) {
            return Err(
                NewUIErrorV2(actionType, EntityKind.ReviewManualCard, err as IUIError)
            );
        }
    }
}

export class ReviewManualGRPC
    implements IGRPCService<
        ReviewManualDTO,
        DTOCreatorRequestType,
        DTOCreatorResponseType<ReviewManualDTO>
    >,
        IGRPCReviewService<
            ReviewManualDTO,
            ReviewManualCardDTO,
            ReviewManualStatDTO,
            ReviewManualOptionsDTO,
            DTOCreatorRequestType,
            DTOCreatorResponseType<ReviewManualDTO>
        > {
    setupListByIDsReq(ids: UUID_DTO[]): DTOCreatorRequestType {
        let req = new ListReviewManualByIdsRequest();
        req.setIdsList(ids);
        
        return req;
    }
    
    async listByIDs(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ListResponse<ReviewManualDTO> | undefined> {
        let x = await reviewManualClient.listByIds(req as ListReviewManualByIdsRequest, meta);

        return {
            items: x.getItemsList() as ReviewManualDTO[],
            info: x.getInfo(),
        } as ListResponse<ReviewManualDTO>;
    }
    
    create(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ReviewManualDTO | undefined> {
        return reviewManualClient
            .create(req as CreateReviewManualRequest, meta)
            .then((x) => {
                return x.getSession() as ReviewManualDTO;
            });
    }
    
    setupCreateReq(dto: ReviewManualDTO): DTOCreatorRequestType {
        let req = new CreateReviewManualRequest();
        req.setSession(dto);
        
        return req;
    }
    
    async list(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ListResponse<ReviewManualDTO> | undefined> {
        const x = await reviewManualClient.list(
            req as ListReviewManualRequest,
            meta
        );

        return {
            items: x.getItemsList() as ReviewManualDTO[],
            info: x.getInfo(),
        } as ListResponse<ReviewManualDTO>;
    }
    
    setupListReq(dto: ListOptionsRequestDTO): DTOCreatorRequestType {
        let req = new ListReviewManualRequest();
        req.setOpts(dto);
        
        return req;
    }
    
    delete(req: DTOCreatorRequestType, meta?: grpcWeb.Metadata): Promise<void> {
        return reviewManualClient
            .delete(req as DeleteReviewManualRequest, meta)
            .then((x) => {
                return;
            });
    }
    
    setupDeleteReq(id: string): DTOCreatorRequestType {
        let req = new DeleteReviewManualRequest();
        req.setReviewid(new UUID_DTO().setValue(id));
        
        return req;
    }
    
    get(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ReviewManualDTO | undefined> {
        return reviewManualClient
            .get(req as GetReviewManualRequest, meta)
            .then((x) => {
                return x.getSession() as ReviewManualDTO;
            });
    }
    
    setupGetReq(id: string): DTOCreatorRequestType {
        let req = new GetReviewManualRequest();
        req.setId(new UUID_DTO().setValue(id));
        
        return req;
    }
    
    setupUpdateReq(dto: ReviewManualDTO): DTOCreatorRequestType {
        let req = new UpdateManualReviewRequest();
        req.setReview(dto);
        
        return req;
    }
    
    update(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ReviewManualDTO | undefined> {
        return reviewManualClient
            .updateManualReview(req as UpdateManualReviewRequest, meta)
            .then((x) => {
                return x.getReview() as ReviewManualDTO;
            });
    }
    
    // setupSaveReq(dto: ReviewManualDTO, cardDTO: ReviewManualCardDTO): DTOCreatorRequestType {
    // 	let req = new SaveManualR()
    // 	req.setId(dto.getId()!)
    // 	req.setCard(cardDTO)
    //
    // 	return req
    // }
    //
    // save(req: DTOCreatorRequestType, meta?: grpcWeb.Metadata): Promise<ReviewManualDTO | undefined> {
    // 	return manualReviewClient.saveManualReviewCardSession(req as SaveManualReviewSessionCardRequest, meta)
    // 		.then((x) => {
    // 			return x. as ReviewManualDTO
    // 		})
    // }
    
    setupCompleteReq(dto: ReviewManualDTO): DTOCreatorRequestType {
        let req = new CompleteManualReviewRequest();
        req.setReview(dto);
        
        return req;
    }
    
    async complete(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ReviewManualDTO | undefined> {
        const x = await reviewManualClient.completeManualReview(
            req as CompleteManualReviewRequest,
            meta
        );
        return x.getReview() as ReviewManualDTO;
    }
    
    setupSaveCardReq(
        review_id: string,
        dto: ReviewManualCardDTO
    ): DTOCreatorRequestType {
        let req = new SaveManualReviewSessionCardRequest();
        req.setCard(dto);
        
        return req;
    }
    
    async saveCard(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ReviewManualCardDTO | undefined> {
        const x = await reviewManualClient.saveManualReviewCardSession(
            req as SaveManualReviewSessionCardRequest,
            meta
        );
        return x.getCard() as ReviewManualCardDTO;
    }
    
    setupSaveCardWithNextReq(
        review_id: string,
        next_review_card_id: string,
        dto: ReviewManualCardDTO
    ): DTOCreatorRequestType {
        let req = new SaveManualReviewSessionCardRequest();
        req.setId(new UUID_DTO().setValue(review_id));
        req.setNextReviewCardId(new UUID_DTO().setValue(next_review_card_id));
        req.setCard(dto);
        
        return req;
    }
    
    setupGetStatsReq(id: string): DTOCreatorRequestType {
        let req = new GetReviewManualStatsRequest();
        req.setId(new UUID_DTO().setValue(id));
        
        return req;
    }
    
    async getStats(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ReviewManualStatDTO | undefined> {
        const x = await reviewManualClient.getReviewManualStats(
            req as GetReviewManualStatsRequest,
            meta
        );
        return x.getStats() as ReviewManualStatDTO;
    }
    
    setupListCardsReq(id: string): DTOCreatorRequestType {
        let req = new ListManualCardsRequest();
        req.setReviewid(new UUID_DTO().setValue(id));
        
        return req;
    }
    
    async listCards(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ReviewManualCardDTO[] | undefined> {
        const x = await reviewManualClient.listManualCards(
            req as ListManualCardsRequest,
            meta
        );
        return x.getManualcardsList() as ReviewManualCardDTO[];
    }
    
    setupListCardsByConfigReq(
        reviewId: string,
        config: ReviewManualOptionsDTO
    ): DTOCreatorRequestType {
        let req = new ListCardsToReviewByConfigRequest();
        req.setReviewid(new UUID_DTO().setValue(reviewId));
        req.setOptions(config);
        
        return req;
    }
    
    async listCardsByConfig(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ReviewManualCardDTO[] | undefined> {
        const x = await reviewManualClient.listCardsToReviewByConfig(
            req as ListCardsToReviewByConfigRequest,
            meta
        );
        return x.getCardList() as ReviewManualCardDTO[];
    }
}
