import {User, UserAttributes, UserSubscription} from "model/user";
import {action, makeObservable, observable, runInAction} from "mobx";
import {ActionType, isError, IUIError, LogErrorToProvider, NewUIErrorV2} from "service/cartaError";
import {Err, Ok, Result} from "utils/result";
import {UserServicePromiseClient} from "proto/user_grpc_web_pb";
import {GetMeRequest, UpdateUserRequest, UserAttributeDTO} from "proto/user_pb";
import {EntityKind} from "model/BaseModel";
import {setUser} from "service/AuthService";
import {CARTA_PROXY_URL} from "consts";

const userClient = new UserServicePromiseClient(
    CARTA_PROXY_URL!,
    null,
    {withCredentials: true}
);

export class UserStore {
    public me: User | undefined;
    public subscriptions: UserSubscription[] = [];

    constructor() {
        makeObservable(this, {
            me: observable,
            subscriptions: observable,
            GetMe: action
        })
    }
    
    Clear() {
        this.me = undefined;
        this.subscriptions = [];
    }

    public async GetMe(): Promise<Result<User, IUIError>> {
        let req: GetMeRequest = new GetMeRequest();

        try {
            const response = await userClient.getMe(req);

            if (response.getUser() === undefined) {
                throw new Error("returned getMe user is undefined")
            }

            let user = new User();
            user.fromDTO(response.getUser()!);

            let subscriptions: UserSubscription[] = [];
             response.getSubscriptionList().forEach((sub) => {
                let subscription = new UserSubscription();
                subscription.fromDTO(sub);
                this.subscriptions.push(subscription);
            });

            if (!isError(user)) {
                runInAction(() => {
                    this.me = user;
                    this.subscriptions = subscriptions;
                });
            }

            return Ok(user);
        } catch (e) {
            return Err(NewUIErrorV2(ActionType.Get, EntityKind.User, e, "error fetching getMe user"))
        }
    }

    public async UpdateUserAttr(attr: UserAttributes): Promise<Result<User, IUIError>> {
        let request = attr.intoDTO();
        if (isError(request)) {
            return Err(NewUIErrorV2(ActionType.Get, EntityKind.User, request, "error converting user attributes to DTO"))
        }

        try {
            const response = await userClient.updateUserAttr(request as UserAttributeDTO);

            let user = new User();
            user.fromDTO(response);

            if (!isError(user)) {
                runInAction(() => {
                    this.me = user;
                });
            }

            // Store the new user in local storage
            setUser(user);

            return Ok(user);
        } catch (e) {
            return Err(NewUIErrorV2(ActionType.Get, EntityKind.User, e, "error updating user attr"))
        }
    }

    public async UpdateUser(displayName: string, bio: string,): Promise<Result<User, IUIError>> {
        let request = new UpdateUserRequest()
        request.setDisplayName(displayName);
        request.setBio(bio);

        try {
            const response = await userClient.updateUser(request);

            let user = new User();
            user.fromDTO(response);

            if (!isError(user)) {
                runInAction(() => {
                    this.me = user;
                });
            }

            // Store the new user in local storage
            setUser(user);

            return Ok(user);
        } catch (e) {
            return Err(NewUIErrorV2(ActionType.Get, EntityKind.User, e, "error updating user"))
        }
    }

    public async CompleteTour(): Promise<Result<User, IUIError>> {
        let attr: UserAttributes;

        // We fetch the user from the DB to get the latest attributes, we cant grab them from
        // the client as we store this in localStorage which could be manipulated or out of state
        try {
            let user = await this.GetMe();
            if (user.ok) {
                attr = user.value.attrs;
            } else {
                throw NewUIErrorV2(ActionType.Get, EntityKind.User, user.error, "error fetching getMe user for user attribute update")
            }
        } catch(e) {
            return LogErrorToProvider(NewUIErrorV2(ActionType.Get, EntityKind.User, e, "fatal - error fetching getMe user for user attribute update"))
        }
        
        try {
            attr.introTour.isComplete = true;
            let request = attr.intoDTO();
            if (isError(request)) {
                throw NewUIErrorV2(ActionType.Get, EntityKind.User, request, "error converting user attributes to DTO")
            }

            const response = await userClient.updateUserAttr(request as UserAttributeDTO);

            let user = new User();
            user.fromDTO(response);

            if (!isError(user)) {
                runInAction(() => {
                    this.me = user;
                });
            }

            // Store the new user in local storage
            setUser(user);

            return Ok(user);
        } catch (e) {
            return Err(NewUIErrorV2(ActionType.Get, EntityKind.User, e, "fatal - error updating user attribute for intro tour completion"))
        }
    }
}
