/**
 * @fileoverview gRPC-Web generated client stub for resource
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: resource.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var utils_pb = require('./utils_pb.js')
const proto = {};
proto.resource = require('./resource_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.resource.ResourceServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.resource.ResourceServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.resource.CreateResourceRequest,
 *   !proto.resource.CreateResourceResponse>}
 */
const methodDescriptor_ResourceService_create = new grpc.web.MethodDescriptor(
  '/resource.ResourceService/create',
  grpc.web.MethodType.UNARY,
  proto.resource.CreateResourceRequest,
  proto.resource.CreateResourceResponse,
  /**
   * @param {!proto.resource.CreateResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.resource.CreateResourceResponse.deserializeBinary
);


/**
 * @param {!proto.resource.CreateResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.resource.CreateResourceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.resource.CreateResourceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.resource.ResourceServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/resource.ResourceService/create',
      request,
      metadata || {},
      methodDescriptor_ResourceService_create,
      callback);
};


/**
 * @param {!proto.resource.CreateResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.resource.CreateResourceResponse>}
 *     Promise that resolves to the response
 */
proto.resource.ResourceServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/resource.ResourceService/create',
      request,
      metadata || {},
      methodDescriptor_ResourceService_create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.resource.GetResourceRequest,
 *   !proto.resource.GetResourceResponse>}
 */
const methodDescriptor_ResourceService_get = new grpc.web.MethodDescriptor(
  '/resource.ResourceService/get',
  grpc.web.MethodType.UNARY,
  proto.resource.GetResourceRequest,
  proto.resource.GetResourceResponse,
  /**
   * @param {!proto.resource.GetResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.resource.GetResourceResponse.deserializeBinary
);


/**
 * @param {!proto.resource.GetResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.resource.GetResourceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.resource.GetResourceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.resource.ResourceServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/resource.ResourceService/get',
      request,
      metadata || {},
      methodDescriptor_ResourceService_get,
      callback);
};


/**
 * @param {!proto.resource.GetResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.resource.GetResourceResponse>}
 *     Promise that resolves to the response
 */
proto.resource.ResourceServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/resource.ResourceService/get',
      request,
      metadata || {},
      methodDescriptor_ResourceService_get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.resource.ListResourceRequest,
 *   !proto.resource.ListResourceResponse>}
 */
const methodDescriptor_ResourceService_list = new grpc.web.MethodDescriptor(
  '/resource.ResourceService/list',
  grpc.web.MethodType.UNARY,
  proto.resource.ListResourceRequest,
  proto.resource.ListResourceResponse,
  /**
   * @param {!proto.resource.ListResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.resource.ListResourceResponse.deserializeBinary
);


/**
 * @param {!proto.resource.ListResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.resource.ListResourceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.resource.ListResourceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.resource.ResourceServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/resource.ResourceService/list',
      request,
      metadata || {},
      methodDescriptor_ResourceService_list,
      callback);
};


/**
 * @param {!proto.resource.ListResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.resource.ListResourceResponse>}
 *     Promise that resolves to the response
 */
proto.resource.ResourceServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/resource.ResourceService/list',
      request,
      metadata || {},
      methodDescriptor_ResourceService_list);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.resource.ListResourceByIdsRequest,
 *   !proto.resource.ListResourceByIdsResponse>}
 */
const methodDescriptor_ResourceService_listByIds = new grpc.web.MethodDescriptor(
  '/resource.ResourceService/listByIds',
  grpc.web.MethodType.UNARY,
  proto.resource.ListResourceByIdsRequest,
  proto.resource.ListResourceByIdsResponse,
  /**
   * @param {!proto.resource.ListResourceByIdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.resource.ListResourceByIdsResponse.deserializeBinary
);


/**
 * @param {!proto.resource.ListResourceByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.resource.ListResourceByIdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.resource.ListResourceByIdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.resource.ResourceServiceClient.prototype.listByIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/resource.ResourceService/listByIds',
      request,
      metadata || {},
      methodDescriptor_ResourceService_listByIds,
      callback);
};


/**
 * @param {!proto.resource.ListResourceByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.resource.ListResourceByIdsResponse>}
 *     Promise that resolves to the response
 */
proto.resource.ResourceServicePromiseClient.prototype.listByIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/resource.ResourceService/listByIds',
      request,
      metadata || {},
      methodDescriptor_ResourceService_listByIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.resource.UpdateResourceRequest,
 *   !proto.resource.UpdateResourceResponse>}
 */
const methodDescriptor_ResourceService_update = new grpc.web.MethodDescriptor(
  '/resource.ResourceService/update',
  grpc.web.MethodType.UNARY,
  proto.resource.UpdateResourceRequest,
  proto.resource.UpdateResourceResponse,
  /**
   * @param {!proto.resource.UpdateResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.resource.UpdateResourceResponse.deserializeBinary
);


/**
 * @param {!proto.resource.UpdateResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.resource.UpdateResourceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.resource.UpdateResourceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.resource.ResourceServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/resource.ResourceService/update',
      request,
      metadata || {},
      methodDescriptor_ResourceService_update,
      callback);
};


/**
 * @param {!proto.resource.UpdateResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.resource.UpdateResourceResponse>}
 *     Promise that resolves to the response
 */
proto.resource.ResourceServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/resource.ResourceService/update',
      request,
      metadata || {},
      methodDescriptor_ResourceService_update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.resource.DeleteResourceRequest,
 *   !proto.resource.DeleteResourceResponse>}
 */
const methodDescriptor_ResourceService_delete = new grpc.web.MethodDescriptor(
  '/resource.ResourceService/delete',
  grpc.web.MethodType.UNARY,
  proto.resource.DeleteResourceRequest,
  proto.resource.DeleteResourceResponse,
  /**
   * @param {!proto.resource.DeleteResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.resource.DeleteResourceResponse.deserializeBinary
);


/**
 * @param {!proto.resource.DeleteResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.resource.DeleteResourceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.resource.DeleteResourceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.resource.ResourceServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/resource.ResourceService/delete',
      request,
      metadata || {},
      methodDescriptor_ResourceService_delete,
      callback);
};


/**
 * @param {!proto.resource.DeleteResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.resource.DeleteResourceResponse>}
 *     Promise that resolves to the response
 */
proto.resource.ResourceServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/resource.ResourceService/delete',
      request,
      metadata || {},
      methodDescriptor_ResourceService_delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.resource.ResourceMetadataDTO,
 *   !proto.resource.ResourceMetadataDTO>}
 */
const methodDescriptor_ResourceService_updateMetadata = new grpc.web.MethodDescriptor(
  '/resource.ResourceService/updateMetadata',
  grpc.web.MethodType.UNARY,
  proto.resource.ResourceMetadataDTO,
  proto.resource.ResourceMetadataDTO,
  /**
   * @param {!proto.resource.ResourceMetadataDTO} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.resource.ResourceMetadataDTO.deserializeBinary
);


/**
 * @param {!proto.resource.ResourceMetadataDTO} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.resource.ResourceMetadataDTO)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.resource.ResourceMetadataDTO>|undefined}
 *     The XHR Node Readable Stream
 */
proto.resource.ResourceServiceClient.prototype.updateMetadata =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/resource.ResourceService/updateMetadata',
      request,
      metadata || {},
      methodDescriptor_ResourceService_updateMetadata,
      callback);
};


/**
 * @param {!proto.resource.ResourceMetadataDTO} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.resource.ResourceMetadataDTO>}
 *     Promise that resolves to the response
 */
proto.resource.ResourceServicePromiseClient.prototype.updateMetadata =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/resource.ResourceService/updateMetadata',
      request,
      metadata || {},
      methodDescriptor_ResourceService_updateMetadata);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.resource.DeleteResourceMetadataRequest,
 *   !proto.resource.DeleteResourceMetadataResponse>}
 */
const methodDescriptor_ResourceService_deleteMetadata = new grpc.web.MethodDescriptor(
  '/resource.ResourceService/deleteMetadata',
  grpc.web.MethodType.UNARY,
  proto.resource.DeleteResourceMetadataRequest,
  proto.resource.DeleteResourceMetadataResponse,
  /**
   * @param {!proto.resource.DeleteResourceMetadataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.resource.DeleteResourceMetadataResponse.deserializeBinary
);


/**
 * @param {!proto.resource.DeleteResourceMetadataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.resource.DeleteResourceMetadataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.resource.DeleteResourceMetadataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.resource.ResourceServiceClient.prototype.deleteMetadata =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/resource.ResourceService/deleteMetadata',
      request,
      metadata || {},
      methodDescriptor_ResourceService_deleteMetadata,
      callback);
};


/**
 * @param {!proto.resource.DeleteResourceMetadataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.resource.DeleteResourceMetadataResponse>}
 *     Promise that resolves to the response
 */
proto.resource.ResourceServicePromiseClient.prototype.deleteMetadata =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/resource.ResourceService/deleteMetadata',
      request,
      metadata || {},
      methodDescriptor_ResourceService_deleteMetadata);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.resource.ArchiveResourceRequest,
 *   !proto.resource.ArchiveResourceResponse>}
 */
const methodDescriptor_ResourceService_archive = new grpc.web.MethodDescriptor(
  '/resource.ResourceService/archive',
  grpc.web.MethodType.UNARY,
  proto.resource.ArchiveResourceRequest,
  proto.resource.ArchiveResourceResponse,
  /**
   * @param {!proto.resource.ArchiveResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.resource.ArchiveResourceResponse.deserializeBinary
);


/**
 * @param {!proto.resource.ArchiveResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.resource.ArchiveResourceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.resource.ArchiveResourceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.resource.ResourceServiceClient.prototype.archive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/resource.ResourceService/archive',
      request,
      metadata || {},
      methodDescriptor_ResourceService_archive,
      callback);
};


/**
 * @param {!proto.resource.ArchiveResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.resource.ArchiveResourceResponse>}
 *     Promise that resolves to the response
 */
proto.resource.ResourceServicePromiseClient.prototype.archive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/resource.ResourceService/archive',
      request,
      metadata || {},
      methodDescriptor_ResourceService_archive);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.resource.SearchGoogleBooksRequest,
 *   !proto.resource.SearchGoogleBooksResponse>}
 */
const methodDescriptor_ResourceService_searchGoogleBooks = new grpc.web.MethodDescriptor(
  '/resource.ResourceService/searchGoogleBooks',
  grpc.web.MethodType.UNARY,
  proto.resource.SearchGoogleBooksRequest,
  proto.resource.SearchGoogleBooksResponse,
  /**
   * @param {!proto.resource.SearchGoogleBooksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.resource.SearchGoogleBooksResponse.deserializeBinary
);


/**
 * @param {!proto.resource.SearchGoogleBooksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.resource.SearchGoogleBooksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.resource.SearchGoogleBooksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.resource.ResourceServiceClient.prototype.searchGoogleBooks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/resource.ResourceService/searchGoogleBooks',
      request,
      metadata || {},
      methodDescriptor_ResourceService_searchGoogleBooks,
      callback);
};


/**
 * @param {!proto.resource.SearchGoogleBooksRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.resource.SearchGoogleBooksResponse>}
 *     Promise that resolves to the response
 */
proto.resource.ResourceServicePromiseClient.prototype.searchGoogleBooks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/resource.ResourceService/searchGoogleBooks',
      request,
      metadata || {},
      methodDescriptor_ResourceService_searchGoogleBooks);
};


module.exports = proto.resource;

