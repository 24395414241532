// material-ui
import {useTheme} from '@mui/material/styles';
import {Chip, Link, useMediaQuery} from '@mui/material';

// project import
import NavCard from './NavCard';
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import {useGetMenuMaster} from 'api/menu';
import {getSubscriptionProduct} from "service/AuthService";
import {Link as RouterLink} from "react-router-dom";
import {convertSubscriptionProductToString} from "model/user";
import {EnumSubscriptionProductDTO} from "proto/stripe_pb";
import {useEffect, useState} from "react";
import * as Sentry from "@sentry/react";
// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
    const theme = useTheme();
    const {menuMaster} = useGetMenuMaster();
    const drawerOpen = menuMaster.isDashboardDrawerOpened;
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    
    const [subscriptionProduct, setSubscriptionProduct] = useState<EnumSubscriptionProductDTO>(EnumSubscriptionProductDTO.FREE);
    
    useEffect(() => {
        try {
            const subscriptionProduct = getSubscriptionProduct()
            setSubscriptionProduct(subscriptionProduct)
        } catch(e) {
            Sentry.captureException(e)
        }
    }, [])

    const subscriptionColor = (subscriptionProduct: EnumSubscriptionProductDTO): string => {
        switch (subscriptionProduct) {
            case EnumSubscriptionProductDTO.FREE:
                return 'secondary';
            case EnumSubscriptionProductDTO.STANDARD:
                return 'primary';
            case EnumSubscriptionProductDTO.PRO:
                return 'success';
            default:
                return 'secondary';
        }
    }
    
    return (
        <>
            <SimpleBar
                sx={{
                    '& .simplebar-content': {
                        display: 'flex',
                        flexDirection: 'column'
                    }
                }}
            >
                <Navigation/>
                {drawerOpen && !matchDownMD && <NavCard/>}
            </SimpleBar>

            {
                convertSubscriptionProductToString(subscriptionProduct) &&
                <Link className="header-link" color="white" component={RouterLink}
                      to={"/profiles/account/subscriptions"}
                      underline="none">
                    <Chip
                        label={convertSubscriptionProductToString(subscriptionProduct)}
                        color="primary"
                        size={'large'}
                        variant="outlined"
                        clickable={true}
                        sx={{
                            m: 1,
                            width: 'calc(100% - 16px)',
                            borderColor: theme.palette.primary.main,
                            color: subscriptionColor(subscriptionProduct)
                        }}/>
                </Link>
            }
            {/*<NavUser />*/}
        </>
    );
};

export default DrawerContent;
