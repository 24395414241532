/**
 * @fileoverview gRPC-Web generated client stub for tag
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: tag.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var utils_pb = require('./utils_pb.js')
const proto = {};
proto.tag = require('./tag_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.tag.TagServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.tag.TagServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tag.CreateTagRequest,
 *   !proto.tag.CreateTagResponse>}
 */
const methodDescriptor_TagService_create = new grpc.web.MethodDescriptor(
  '/tag.TagService/create',
  grpc.web.MethodType.UNARY,
  proto.tag.CreateTagRequest,
  proto.tag.CreateTagResponse,
  /**
   * @param {!proto.tag.CreateTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tag.CreateTagResponse.deserializeBinary
);


/**
 * @param {!proto.tag.CreateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.tag.CreateTagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tag.CreateTagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tag.TagServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tag.TagService/create',
      request,
      metadata || {},
      methodDescriptor_TagService_create,
      callback);
};


/**
 * @param {!proto.tag.CreateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tag.CreateTagResponse>}
 *     Promise that resolves to the response
 */
proto.tag.TagServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tag.TagService/create',
      request,
      metadata || {},
      methodDescriptor_TagService_create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tag.GetTagRequest,
 *   !proto.tag.GetTagResponse>}
 */
const methodDescriptor_TagService_get = new grpc.web.MethodDescriptor(
  '/tag.TagService/get',
  grpc.web.MethodType.UNARY,
  proto.tag.GetTagRequest,
  proto.tag.GetTagResponse,
  /**
   * @param {!proto.tag.GetTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tag.GetTagResponse.deserializeBinary
);


/**
 * @param {!proto.tag.GetTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.tag.GetTagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tag.GetTagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tag.TagServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tag.TagService/get',
      request,
      metadata || {},
      methodDescriptor_TagService_get,
      callback);
};


/**
 * @param {!proto.tag.GetTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tag.GetTagResponse>}
 *     Promise that resolves to the response
 */
proto.tag.TagServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tag.TagService/get',
      request,
      metadata || {},
      methodDescriptor_TagService_get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tag.ListTagRequest,
 *   !proto.tag.ListTagResponse>}
 */
const methodDescriptor_TagService_list = new grpc.web.MethodDescriptor(
  '/tag.TagService/list',
  grpc.web.MethodType.UNARY,
  proto.tag.ListTagRequest,
  proto.tag.ListTagResponse,
  /**
   * @param {!proto.tag.ListTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tag.ListTagResponse.deserializeBinary
);


/**
 * @param {!proto.tag.ListTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.tag.ListTagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tag.ListTagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tag.TagServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tag.TagService/list',
      request,
      metadata || {},
      methodDescriptor_TagService_list,
      callback);
};


/**
 * @param {!proto.tag.ListTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tag.ListTagResponse>}
 *     Promise that resolves to the response
 */
proto.tag.TagServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tag.TagService/list',
      request,
      metadata || {},
      methodDescriptor_TagService_list);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tag.ListTagByIdsRequest,
 *   !proto.tag.ListTagByIdsResponse>}
 */
const methodDescriptor_TagService_listByIds = new grpc.web.MethodDescriptor(
  '/tag.TagService/listByIds',
  grpc.web.MethodType.UNARY,
  proto.tag.ListTagByIdsRequest,
  proto.tag.ListTagByIdsResponse,
  /**
   * @param {!proto.tag.ListTagByIdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tag.ListTagByIdsResponse.deserializeBinary
);


/**
 * @param {!proto.tag.ListTagByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.tag.ListTagByIdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tag.ListTagByIdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tag.TagServiceClient.prototype.listByIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tag.TagService/listByIds',
      request,
      metadata || {},
      methodDescriptor_TagService_listByIds,
      callback);
};


/**
 * @param {!proto.tag.ListTagByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tag.ListTagByIdsResponse>}
 *     Promise that resolves to the response
 */
proto.tag.TagServicePromiseClient.prototype.listByIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tag.TagService/listByIds',
      request,
      metadata || {},
      methodDescriptor_TagService_listByIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tag.UpdateTagRequest,
 *   !proto.tag.UpdateTagResponse>}
 */
const methodDescriptor_TagService_update = new grpc.web.MethodDescriptor(
  '/tag.TagService/update',
  grpc.web.MethodType.UNARY,
  proto.tag.UpdateTagRequest,
  proto.tag.UpdateTagResponse,
  /**
   * @param {!proto.tag.UpdateTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tag.UpdateTagResponse.deserializeBinary
);


/**
 * @param {!proto.tag.UpdateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.tag.UpdateTagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tag.UpdateTagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tag.TagServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tag.TagService/update',
      request,
      metadata || {},
      methodDescriptor_TagService_update,
      callback);
};


/**
 * @param {!proto.tag.UpdateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tag.UpdateTagResponse>}
 *     Promise that resolves to the response
 */
proto.tag.TagServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tag.TagService/update',
      request,
      metadata || {},
      methodDescriptor_TagService_update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tag.DeleteTagRequest,
 *   !proto.tag.DeleteTagResponse>}
 */
const methodDescriptor_TagService_delete = new grpc.web.MethodDescriptor(
  '/tag.TagService/delete',
  grpc.web.MethodType.UNARY,
  proto.tag.DeleteTagRequest,
  proto.tag.DeleteTagResponse,
  /**
   * @param {!proto.tag.DeleteTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tag.DeleteTagResponse.deserializeBinary
);


/**
 * @param {!proto.tag.DeleteTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.tag.DeleteTagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tag.DeleteTagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tag.TagServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tag.TagService/delete',
      request,
      metadata || {},
      methodDescriptor_TagService_delete,
      callback);
};


/**
 * @param {!proto.tag.DeleteTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tag.DeleteTagResponse>}
 *     Promise that resolves to the response
 */
proto.tag.TagServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tag.TagService/delete',
      request,
      metadata || {},
      methodDescriptor_TagService_delete);
};


module.exports = proto.tag;

