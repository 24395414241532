/**
 * @fileoverview gRPC-Web generated client stub for cardLang
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: cardLang.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var utils_pb = require('./utils_pb.js')

var tag_pb = require('./tag_pb.js')

var topic_pb = require('./topic_pb.js')

var resource_pb = require('./resource_pb.js')

var language_pb = require('./language_pb.js')
const proto = {};
proto.cardLang = require('./cardLang_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.cardLang.CardLangServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.cardLang.CardLangServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.cardLang.CreateCardLangRequest,
 *   !proto.cardLang.CreateCardLangResponse>}
 */
const methodDescriptor_CardLangService_create = new grpc.web.MethodDescriptor(
  '/cardLang.CardLangService/create',
  grpc.web.MethodType.UNARY,
  proto.cardLang.CreateCardLangRequest,
  proto.cardLang.CreateCardLangResponse,
  /**
   * @param {!proto.cardLang.CreateCardLangRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cardLang.CreateCardLangResponse.deserializeBinary
);


/**
 * @param {!proto.cardLang.CreateCardLangRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.cardLang.CreateCardLangResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.cardLang.CreateCardLangResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.cardLang.CardLangServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/cardLang.CardLangService/create',
      request,
      metadata || {},
      methodDescriptor_CardLangService_create,
      callback);
};


/**
 * @param {!proto.cardLang.CreateCardLangRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.cardLang.CreateCardLangResponse>}
 *     Promise that resolves to the response
 */
proto.cardLang.CardLangServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/cardLang.CardLangService/create',
      request,
      metadata || {},
      methodDescriptor_CardLangService_create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.cardLang.GetCardLangRequest,
 *   !proto.cardLang.GetCardLangResponse>}
 */
const methodDescriptor_CardLangService_get = new grpc.web.MethodDescriptor(
  '/cardLang.CardLangService/get',
  grpc.web.MethodType.UNARY,
  proto.cardLang.GetCardLangRequest,
  proto.cardLang.GetCardLangResponse,
  /**
   * @param {!proto.cardLang.GetCardLangRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cardLang.GetCardLangResponse.deserializeBinary
);


/**
 * @param {!proto.cardLang.GetCardLangRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.cardLang.GetCardLangResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.cardLang.GetCardLangResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.cardLang.CardLangServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/cardLang.CardLangService/get',
      request,
      metadata || {},
      methodDescriptor_CardLangService_get,
      callback);
};


/**
 * @param {!proto.cardLang.GetCardLangRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.cardLang.GetCardLangResponse>}
 *     Promise that resolves to the response
 */
proto.cardLang.CardLangServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/cardLang.CardLangService/get',
      request,
      metadata || {},
      methodDescriptor_CardLangService_get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.cardLang.ListCardLangRequest,
 *   !proto.cardLang.ListCardLangResponse>}
 */
const methodDescriptor_CardLangService_list = new grpc.web.MethodDescriptor(
  '/cardLang.CardLangService/list',
  grpc.web.MethodType.UNARY,
  proto.cardLang.ListCardLangRequest,
  proto.cardLang.ListCardLangResponse,
  /**
   * @param {!proto.cardLang.ListCardLangRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cardLang.ListCardLangResponse.deserializeBinary
);


/**
 * @param {!proto.cardLang.ListCardLangRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.cardLang.ListCardLangResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.cardLang.ListCardLangResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.cardLang.CardLangServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/cardLang.CardLangService/list',
      request,
      metadata || {},
      methodDescriptor_CardLangService_list,
      callback);
};


/**
 * @param {!proto.cardLang.ListCardLangRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.cardLang.ListCardLangResponse>}
 *     Promise that resolves to the response
 */
proto.cardLang.CardLangServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/cardLang.CardLangService/list',
      request,
      metadata || {},
      methodDescriptor_CardLangService_list);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.cardLang.ListCardLangByIdsRequest,
 *   !proto.cardLang.ListCardLangByIdsResponse>}
 */
const methodDescriptor_CardLangService_listByIds = new grpc.web.MethodDescriptor(
  '/cardLang.CardLangService/listByIds',
  grpc.web.MethodType.UNARY,
  proto.cardLang.ListCardLangByIdsRequest,
  proto.cardLang.ListCardLangByIdsResponse,
  /**
   * @param {!proto.cardLang.ListCardLangByIdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cardLang.ListCardLangByIdsResponse.deserializeBinary
);


/**
 * @param {!proto.cardLang.ListCardLangByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.cardLang.ListCardLangByIdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.cardLang.ListCardLangByIdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.cardLang.CardLangServiceClient.prototype.listByIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/cardLang.CardLangService/listByIds',
      request,
      metadata || {},
      methodDescriptor_CardLangService_listByIds,
      callback);
};


/**
 * @param {!proto.cardLang.ListCardLangByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.cardLang.ListCardLangByIdsResponse>}
 *     Promise that resolves to the response
 */
proto.cardLang.CardLangServicePromiseClient.prototype.listByIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/cardLang.CardLangService/listByIds',
      request,
      metadata || {},
      methodDescriptor_CardLangService_listByIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.cardLang.DeleteCardLangRequest,
 *   !proto.cardLang.DeleteCardLangResponse>}
 */
const methodDescriptor_CardLangService_delete = new grpc.web.MethodDescriptor(
  '/cardLang.CardLangService/delete',
  grpc.web.MethodType.UNARY,
  proto.cardLang.DeleteCardLangRequest,
  proto.cardLang.DeleteCardLangResponse,
  /**
   * @param {!proto.cardLang.DeleteCardLangRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cardLang.DeleteCardLangResponse.deserializeBinary
);


/**
 * @param {!proto.cardLang.DeleteCardLangRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.cardLang.DeleteCardLangResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.cardLang.DeleteCardLangResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.cardLang.CardLangServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/cardLang.CardLangService/delete',
      request,
      metadata || {},
      methodDescriptor_CardLangService_delete,
      callback);
};


/**
 * @param {!proto.cardLang.DeleteCardLangRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.cardLang.DeleteCardLangResponse>}
 *     Promise that resolves to the response
 */
proto.cardLang.CardLangServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/cardLang.CardLangService/delete',
      request,
      metadata || {},
      methodDescriptor_CardLangService_delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.cardLang.UpdateCardLangRequest,
 *   !proto.cardLang.UpdateCardLangResponse>}
 */
const methodDescriptor_CardLangService_update = new grpc.web.MethodDescriptor(
  '/cardLang.CardLangService/update',
  grpc.web.MethodType.UNARY,
  proto.cardLang.UpdateCardLangRequest,
  proto.cardLang.UpdateCardLangResponse,
  /**
   * @param {!proto.cardLang.UpdateCardLangRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cardLang.UpdateCardLangResponse.deserializeBinary
);


/**
 * @param {!proto.cardLang.UpdateCardLangRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.cardLang.UpdateCardLangResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.cardLang.UpdateCardLangResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.cardLang.CardLangServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/cardLang.CardLangService/update',
      request,
      metadata || {},
      methodDescriptor_CardLangService_update,
      callback);
};


/**
 * @param {!proto.cardLang.UpdateCardLangRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.cardLang.UpdateCardLangResponse>}
 *     Promise that resolves to the response
 */
proto.cardLang.CardLangServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/cardLang.CardLangService/update',
      request,
      metadata || {},
      methodDescriptor_CardLangService_update);
};


module.exports = proto.cardLang;

