/**
 * @fileoverview gRPC-Web generated client stub for language
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: language.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var utils_pb = require('./utils_pb.js')
const proto = {};
proto.language = require('./language_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.language.LanguageServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.language.LanguageServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.language.FetchLanguageRequest,
 *   !proto.language.FetchLanguageResponse>}
 */
const methodDescriptor_LanguageService_list = new grpc.web.MethodDescriptor(
  '/language.LanguageService/list',
  grpc.web.MethodType.UNARY,
  proto.language.FetchLanguageRequest,
  proto.language.FetchLanguageResponse,
  /**
   * @param {!proto.language.FetchLanguageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.language.FetchLanguageResponse.deserializeBinary
);


/**
 * @param {!proto.language.FetchLanguageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.language.FetchLanguageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.language.FetchLanguageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.language.LanguageServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/language.LanguageService/list',
      request,
      metadata || {},
      methodDescriptor_LanguageService_list,
      callback);
};


/**
 * @param {!proto.language.FetchLanguageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.language.FetchLanguageResponse>}
 *     Promise that resolves to the response
 */
proto.language.LanguageServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/language.LanguageService/list',
      request,
      metadata || {},
      methodDescriptor_LanguageService_list);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.language.ListLanguageByIdsRequest,
 *   !proto.language.ListLanguageByIdsResponse>}
 */
const methodDescriptor_LanguageService_listByIds = new grpc.web.MethodDescriptor(
  '/language.LanguageService/listByIds',
  grpc.web.MethodType.UNARY,
  proto.language.ListLanguageByIdsRequest,
  proto.language.ListLanguageByIdsResponse,
  /**
   * @param {!proto.language.ListLanguageByIdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.language.ListLanguageByIdsResponse.deserializeBinary
);


/**
 * @param {!proto.language.ListLanguageByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.language.ListLanguageByIdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.language.ListLanguageByIdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.language.LanguageServiceClient.prototype.listByIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/language.LanguageService/listByIds',
      request,
      metadata || {},
      methodDescriptor_LanguageService_listByIds,
      callback);
};


/**
 * @param {!proto.language.ListLanguageByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.language.ListLanguageByIdsResponse>}
 *     Promise that resolves to the response
 */
proto.language.LanguageServicePromiseClient.prototype.listByIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/language.LanguageService/listByIds',
      request,
      metadata || {},
      methodDescriptor_LanguageService_listByIds);
};


module.exports = proto.language;

