/**
 * @fileoverview gRPC-Web generated client stub for reviewManual
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: reviewManual.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var utils_pb = require('./utils_pb.js')

var card_pb = require('./card_pb.js')

var cardLang_pb = require('./cardLang_pb.js')

var stats_pb = require('./stats_pb.js')

var topic_pb = require('./topic_pb.js')
const proto = {};
proto.reviewManual = require('./reviewManual_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reviewManual.ReviewManualServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reviewManual.ReviewManualServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewManual.CreateReviewManualRequest,
 *   !proto.reviewManual.CreateReviewManualResponse>}
 */
const methodDescriptor_ReviewManualService_create = new grpc.web.MethodDescriptor(
  '/reviewManual.ReviewManualService/create',
  grpc.web.MethodType.UNARY,
  proto.reviewManual.CreateReviewManualRequest,
  proto.reviewManual.CreateReviewManualResponse,
  /**
   * @param {!proto.reviewManual.CreateReviewManualRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewManual.CreateReviewManualResponse.deserializeBinary
);


/**
 * @param {!proto.reviewManual.CreateReviewManualRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewManual.CreateReviewManualResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewManual.CreateReviewManualResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewManual.ReviewManualServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewManual.ReviewManualService/create',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_create,
      callback);
};


/**
 * @param {!proto.reviewManual.CreateReviewManualRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewManual.CreateReviewManualResponse>}
 *     Promise that resolves to the response
 */
proto.reviewManual.ReviewManualServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewManual.ReviewManualService/create',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewManual.GetReviewManualRequest,
 *   !proto.reviewManual.GetReviewManualResponse>}
 */
const methodDescriptor_ReviewManualService_get = new grpc.web.MethodDescriptor(
  '/reviewManual.ReviewManualService/get',
  grpc.web.MethodType.UNARY,
  proto.reviewManual.GetReviewManualRequest,
  proto.reviewManual.GetReviewManualResponse,
  /**
   * @param {!proto.reviewManual.GetReviewManualRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewManual.GetReviewManualResponse.deserializeBinary
);


/**
 * @param {!proto.reviewManual.GetReviewManualRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewManual.GetReviewManualResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewManual.GetReviewManualResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewManual.ReviewManualServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewManual.ReviewManualService/get',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_get,
      callback);
};


/**
 * @param {!proto.reviewManual.GetReviewManualRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewManual.GetReviewManualResponse>}
 *     Promise that resolves to the response
 */
proto.reviewManual.ReviewManualServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewManual.ReviewManualService/get',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewManual.ListReviewManualRequest,
 *   !proto.reviewManual.ListReviewManualResponse>}
 */
const methodDescriptor_ReviewManualService_list = new grpc.web.MethodDescriptor(
  '/reviewManual.ReviewManualService/list',
  grpc.web.MethodType.UNARY,
  proto.reviewManual.ListReviewManualRequest,
  proto.reviewManual.ListReviewManualResponse,
  /**
   * @param {!proto.reviewManual.ListReviewManualRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewManual.ListReviewManualResponse.deserializeBinary
);


/**
 * @param {!proto.reviewManual.ListReviewManualRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewManual.ListReviewManualResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewManual.ListReviewManualResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewManual.ReviewManualServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewManual.ReviewManualService/list',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_list,
      callback);
};


/**
 * @param {!proto.reviewManual.ListReviewManualRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewManual.ListReviewManualResponse>}
 *     Promise that resolves to the response
 */
proto.reviewManual.ReviewManualServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewManual.ReviewManualService/list',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_list);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewManual.ListReviewManualByIdsRequest,
 *   !proto.reviewManual.ListReviewManualByIdsResponse>}
 */
const methodDescriptor_ReviewManualService_listByIds = new grpc.web.MethodDescriptor(
  '/reviewManual.ReviewManualService/listByIds',
  grpc.web.MethodType.UNARY,
  proto.reviewManual.ListReviewManualByIdsRequest,
  proto.reviewManual.ListReviewManualByIdsResponse,
  /**
   * @param {!proto.reviewManual.ListReviewManualByIdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewManual.ListReviewManualByIdsResponse.deserializeBinary
);


/**
 * @param {!proto.reviewManual.ListReviewManualByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewManual.ListReviewManualByIdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewManual.ListReviewManualByIdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewManual.ReviewManualServiceClient.prototype.listByIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewManual.ReviewManualService/listByIds',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_listByIds,
      callback);
};


/**
 * @param {!proto.reviewManual.ListReviewManualByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewManual.ListReviewManualByIdsResponse>}
 *     Promise that resolves to the response
 */
proto.reviewManual.ReviewManualServicePromiseClient.prototype.listByIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewManual.ReviewManualService/listByIds',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_listByIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewManual.ListManualCardsRequest,
 *   !proto.reviewManual.ListManualCardsResponse>}
 */
const methodDescriptor_ReviewManualService_listManualCards = new grpc.web.MethodDescriptor(
  '/reviewManual.ReviewManualService/listManualCards',
  grpc.web.MethodType.UNARY,
  proto.reviewManual.ListManualCardsRequest,
  proto.reviewManual.ListManualCardsResponse,
  /**
   * @param {!proto.reviewManual.ListManualCardsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewManual.ListManualCardsResponse.deserializeBinary
);


/**
 * @param {!proto.reviewManual.ListManualCardsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewManual.ListManualCardsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewManual.ListManualCardsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewManual.ReviewManualServiceClient.prototype.listManualCards =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewManual.ReviewManualService/listManualCards',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_listManualCards,
      callback);
};


/**
 * @param {!proto.reviewManual.ListManualCardsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewManual.ListManualCardsResponse>}
 *     Promise that resolves to the response
 */
proto.reviewManual.ReviewManualServicePromiseClient.prototype.listManualCards =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewManual.ReviewManualService/listManualCards',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_listManualCards);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewManual.DeleteReviewManualRequest,
 *   !proto.reviewManual.DeleteReviewManualResponse>}
 */
const methodDescriptor_ReviewManualService_delete = new grpc.web.MethodDescriptor(
  '/reviewManual.ReviewManualService/delete',
  grpc.web.MethodType.UNARY,
  proto.reviewManual.DeleteReviewManualRequest,
  proto.reviewManual.DeleteReviewManualResponse,
  /**
   * @param {!proto.reviewManual.DeleteReviewManualRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewManual.DeleteReviewManualResponse.deserializeBinary
);


/**
 * @param {!proto.reviewManual.DeleteReviewManualRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewManual.DeleteReviewManualResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewManual.DeleteReviewManualResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewManual.ReviewManualServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewManual.ReviewManualService/delete',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_delete,
      callback);
};


/**
 * @param {!proto.reviewManual.DeleteReviewManualRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewManual.DeleteReviewManualResponse>}
 *     Promise that resolves to the response
 */
proto.reviewManual.ReviewManualServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewManual.ReviewManualService/delete',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewManual.SaveReviewManualRequest,
 *   !proto.reviewManual.SaveReviewManualResponse>}
 */
const methodDescriptor_ReviewManualService_save = new grpc.web.MethodDescriptor(
  '/reviewManual.ReviewManualService/save',
  grpc.web.MethodType.UNARY,
  proto.reviewManual.SaveReviewManualRequest,
  proto.reviewManual.SaveReviewManualResponse,
  /**
   * @param {!proto.reviewManual.SaveReviewManualRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewManual.SaveReviewManualResponse.deserializeBinary
);


/**
 * @param {!proto.reviewManual.SaveReviewManualRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewManual.SaveReviewManualResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewManual.SaveReviewManualResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewManual.ReviewManualServiceClient.prototype.save =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewManual.ReviewManualService/save',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_save,
      callback);
};


/**
 * @param {!proto.reviewManual.SaveReviewManualRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewManual.SaveReviewManualResponse>}
 *     Promise that resolves to the response
 */
proto.reviewManual.ReviewManualServicePromiseClient.prototype.save =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewManual.ReviewManualService/save',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_save);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewManual.ResumeReviewManualRequest,
 *   !proto.reviewManual.ResumeReviewManualResponse>}
 */
const methodDescriptor_ReviewManualService_resume = new grpc.web.MethodDescriptor(
  '/reviewManual.ReviewManualService/resume',
  grpc.web.MethodType.UNARY,
  proto.reviewManual.ResumeReviewManualRequest,
  proto.reviewManual.ResumeReviewManualResponse,
  /**
   * @param {!proto.reviewManual.ResumeReviewManualRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewManual.ResumeReviewManualResponse.deserializeBinary
);


/**
 * @param {!proto.reviewManual.ResumeReviewManualRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewManual.ResumeReviewManualResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewManual.ResumeReviewManualResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewManual.ReviewManualServiceClient.prototype.resume =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewManual.ReviewManualService/resume',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_resume,
      callback);
};


/**
 * @param {!proto.reviewManual.ResumeReviewManualRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewManual.ResumeReviewManualResponse>}
 *     Promise that resolves to the response
 */
proto.reviewManual.ReviewManualServicePromiseClient.prototype.resume =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewManual.ReviewManualService/resume',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_resume);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewManual.ListCardsToReviewByConfigRequest,
 *   !proto.reviewManual.ListCardsToReviewByConfigResponse>}
 */
const methodDescriptor_ReviewManualService_listCardsToReviewByConfig = new grpc.web.MethodDescriptor(
  '/reviewManual.ReviewManualService/listCardsToReviewByConfig',
  grpc.web.MethodType.UNARY,
  proto.reviewManual.ListCardsToReviewByConfigRequest,
  proto.reviewManual.ListCardsToReviewByConfigResponse,
  /**
   * @param {!proto.reviewManual.ListCardsToReviewByConfigRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewManual.ListCardsToReviewByConfigResponse.deserializeBinary
);


/**
 * @param {!proto.reviewManual.ListCardsToReviewByConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewManual.ListCardsToReviewByConfigResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewManual.ListCardsToReviewByConfigResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewManual.ReviewManualServiceClient.prototype.listCardsToReviewByConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewManual.ReviewManualService/listCardsToReviewByConfig',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_listCardsToReviewByConfig,
      callback);
};


/**
 * @param {!proto.reviewManual.ListCardsToReviewByConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewManual.ListCardsToReviewByConfigResponse>}
 *     Promise that resolves to the response
 */
proto.reviewManual.ReviewManualServicePromiseClient.prototype.listCardsToReviewByConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewManual.ReviewManualService/listCardsToReviewByConfig',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_listCardsToReviewByConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewManual.CompleteManualReviewRequest,
 *   !proto.reviewManual.CompleteManualReviewResponse>}
 */
const methodDescriptor_ReviewManualService_completeManualReview = new grpc.web.MethodDescriptor(
  '/reviewManual.ReviewManualService/completeManualReview',
  grpc.web.MethodType.UNARY,
  proto.reviewManual.CompleteManualReviewRequest,
  proto.reviewManual.CompleteManualReviewResponse,
  /**
   * @param {!proto.reviewManual.CompleteManualReviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewManual.CompleteManualReviewResponse.deserializeBinary
);


/**
 * @param {!proto.reviewManual.CompleteManualReviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewManual.CompleteManualReviewResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewManual.CompleteManualReviewResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewManual.ReviewManualServiceClient.prototype.completeManualReview =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewManual.ReviewManualService/completeManualReview',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_completeManualReview,
      callback);
};


/**
 * @param {!proto.reviewManual.CompleteManualReviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewManual.CompleteManualReviewResponse>}
 *     Promise that resolves to the response
 */
proto.reviewManual.ReviewManualServicePromiseClient.prototype.completeManualReview =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewManual.ReviewManualService/completeManualReview',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_completeManualReview);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewManual.GetReviewManualStatsRequest,
 *   !proto.reviewManual.GetReviewManualStatsResponse>}
 */
const methodDescriptor_ReviewManualService_getReviewManualStats = new grpc.web.MethodDescriptor(
  '/reviewManual.ReviewManualService/getReviewManualStats',
  grpc.web.MethodType.UNARY,
  proto.reviewManual.GetReviewManualStatsRequest,
  proto.reviewManual.GetReviewManualStatsResponse,
  /**
   * @param {!proto.reviewManual.GetReviewManualStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewManual.GetReviewManualStatsResponse.deserializeBinary
);


/**
 * @param {!proto.reviewManual.GetReviewManualStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewManual.GetReviewManualStatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewManual.GetReviewManualStatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewManual.ReviewManualServiceClient.prototype.getReviewManualStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewManual.ReviewManualService/getReviewManualStats',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_getReviewManualStats,
      callback);
};


/**
 * @param {!proto.reviewManual.GetReviewManualStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewManual.GetReviewManualStatsResponse>}
 *     Promise that resolves to the response
 */
proto.reviewManual.ReviewManualServicePromiseClient.prototype.getReviewManualStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewManual.ReviewManualService/getReviewManualStats',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_getReviewManualStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewManual.UpdateManualReviewRequest,
 *   !proto.reviewManual.UpdateManualReviewResponse>}
 */
const methodDescriptor_ReviewManualService_updateManualReview = new grpc.web.MethodDescriptor(
  '/reviewManual.ReviewManualService/updateManualReview',
  grpc.web.MethodType.UNARY,
  proto.reviewManual.UpdateManualReviewRequest,
  proto.reviewManual.UpdateManualReviewResponse,
  /**
   * @param {!proto.reviewManual.UpdateManualReviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewManual.UpdateManualReviewResponse.deserializeBinary
);


/**
 * @param {!proto.reviewManual.UpdateManualReviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewManual.UpdateManualReviewResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewManual.UpdateManualReviewResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewManual.ReviewManualServiceClient.prototype.updateManualReview =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewManual.ReviewManualService/updateManualReview',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_updateManualReview,
      callback);
};


/**
 * @param {!proto.reviewManual.UpdateManualReviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewManual.UpdateManualReviewResponse>}
 *     Promise that resolves to the response
 */
proto.reviewManual.ReviewManualServicePromiseClient.prototype.updateManualReview =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewManual.ReviewManualService/updateManualReview',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_updateManualReview);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewManual.SaveManualReviewSessionCardRequest,
 *   !proto.reviewManual.SaveManualReviewSessionCardResponse>}
 */
const methodDescriptor_ReviewManualService_saveManualReviewCardSession = new grpc.web.MethodDescriptor(
  '/reviewManual.ReviewManualService/saveManualReviewCardSession',
  grpc.web.MethodType.UNARY,
  proto.reviewManual.SaveManualReviewSessionCardRequest,
  proto.reviewManual.SaveManualReviewSessionCardResponse,
  /**
   * @param {!proto.reviewManual.SaveManualReviewSessionCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewManual.SaveManualReviewSessionCardResponse.deserializeBinary
);


/**
 * @param {!proto.reviewManual.SaveManualReviewSessionCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewManual.SaveManualReviewSessionCardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewManual.SaveManualReviewSessionCardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewManual.ReviewManualServiceClient.prototype.saveManualReviewCardSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewManual.ReviewManualService/saveManualReviewCardSession',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_saveManualReviewCardSession,
      callback);
};


/**
 * @param {!proto.reviewManual.SaveManualReviewSessionCardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewManual.SaveManualReviewSessionCardResponse>}
 *     Promise that resolves to the response
 */
proto.reviewManual.ReviewManualServicePromiseClient.prototype.saveManualReviewCardSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewManual.ReviewManualService/saveManualReviewCardSession',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_saveManualReviewCardSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewManual.FilterManualCardsRequest,
 *   !proto.reviewManual.FilterManualCardsResponse>}
 */
const methodDescriptor_ReviewManualService_filterManualCards = new grpc.web.MethodDescriptor(
  '/reviewManual.ReviewManualService/filterManualCards',
  grpc.web.MethodType.UNARY,
  proto.reviewManual.FilterManualCardsRequest,
  proto.reviewManual.FilterManualCardsResponse,
  /**
   * @param {!proto.reviewManual.FilterManualCardsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewManual.FilterManualCardsResponse.deserializeBinary
);


/**
 * @param {!proto.reviewManual.FilterManualCardsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewManual.FilterManualCardsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewManual.FilterManualCardsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewManual.ReviewManualServiceClient.prototype.filterManualCards =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewManual.ReviewManualService/filterManualCards',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_filterManualCards,
      callback);
};


/**
 * @param {!proto.reviewManual.FilterManualCardsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewManual.FilterManualCardsResponse>}
 *     Promise that resolves to the response
 */
proto.reviewManual.ReviewManualServicePromiseClient.prototype.filterManualCards =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewManual.ReviewManualService/filterManualCards',
      request,
      metadata || {},
      methodDescriptor_ReviewManualService_filterManualCards);
};


module.exports = proto.reviewManual;

