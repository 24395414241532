import {IUser, User} from "model/user";
import {AuthObj} from "model/auth";
import {ActionType, IUIError, NewUIErrorV2} from "service/cartaError";
import {EntityKind} from "model/BaseModel";

import {
	LOCAL_STORAGE_ENTITLEMENTS,
	LOCAL_STORAGE_LAST_HEARTBEAT_TIME,
	LOCAL_STORAGE_PRICING_SESSION,
	LOCAL_STORAGE_SIGNUP_PRICING_SESSION,
	LOCAL_STORAGE_SUBSCRIPTION_PRODUCT,
	LOCAL_STORAGE_USER,
	LOCAL_STORAGE_USER_ID
} from "consts";
import {EnumSubscriptionProductDTO} from "proto/stripe_pb";
import {IFromDTO, IIntoDTO, IValidator} from "../model/model";
import {FederatedProviderDTO, GoogleOAuthTokenDTO, SignUpProfileDTO} from "../proto/auth_pb";

export function getUser(): User {
	const user = localStorage.getItem(LOCAL_STORAGE_USER);
	
	if (user == null) {
		throw NewUIErrorV2(ActionType.Authenticate, EntityKind.User, "User not found in local storage")
	}
	
	const res: IUser = JSON.parse(user);
	
	let u = new User();
	u.fromJSON(res)
	
	return u
}

export function getSubscriptionProduct(): EnumSubscriptionProductDTO {
	const product = localStorage.getItem(LOCAL_STORAGE_SUBSCRIPTION_PRODUCT);
	
	if (product == null) {
		throw NewUIErrorV2(ActionType.Authenticate, EntityKind.User, "Subscription product not found in local storage")
	}
	
	const num: number = parseInt(product);
	
	return num as EnumSubscriptionProductDTO;
}

export function storeSubscriptionProduct(product: EnumSubscriptionProductDTO) {
	localStorage.setItem(LOCAL_STORAGE_SUBSCRIPTION_PRODUCT, product.toString());
}

export function setUser(user: User) {
	localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));
}

export function getUserId(): string {
	const id = localStorage.getItem(LOCAL_STORAGE_USER_ID);
	
	if (id == null) {
		throw NewUIErrorV2(ActionType.Authenticate, EntityKind.User, "User ID not found in local storage")
	}
	
	return id;
}

export function storeAuthObjInLocalStorage(authObj: AuthObj) {
	localStorage.setItem(LOCAL_STORAGE_USER_ID, authObj.userId);
	localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(authObj.user));
}

export function removeAuthInfoFromLocalStorage() {
	localStorage.removeItem(LOCAL_STORAGE_USER_ID);
	localStorage.removeItem(LOCAL_STORAGE_USER);
	localStorage.removeItem(LOCAL_STORAGE_PRICING_SESSION);
	localStorage.removeItem(LOCAL_STORAGE_SIGNUP_PRICING_SESSION);
	localStorage.removeItem(LOCAL_STORAGE_ENTITLEMENTS);
	localStorage.removeItem(LOCAL_STORAGE_SUBSCRIPTION_PRODUCT);
}

export function storeHeartBeatTimeInLocalStorage(time: Date) {
	localStorage.setItem(LOCAL_STORAGE_LAST_HEARTBEAT_TIME, time.toISOString());
}

export function getLastHeartBeatTimeFromLocalStorage(): Date | null {
	const time = localStorage.getItem(LOCAL_STORAGE_LAST_HEARTBEAT_TIME);
	
	if (time == null) {
		return null;
	}
	
	return new Date(time);
}

export function retrieveUserFromLocalStorage(): User | null {
	const user = localStorage.getItem(LOCAL_STORAGE_USER);
	
	if (user == null) {
		return null;
	}
	
	return JSON.parse(user);
}

interface IGoogleOAuthToken {
	accessToken: string,
	expiresAt: number,
	expiresIn: number,
	idToken: string,
	firstIssuedAt: number,
	idpId: string,
	tokenType: string,
	refreshToken: string
}

export class GoogleOAuthToken implements IFromDTO<GoogleOAuthTokenDTO>, IIntoDTO<GoogleOAuthTokenDTO>, IValidator<GoogleOAuthToken> {
	accessToken: string = ""
	expiresAt: number = 0
	expiresIn: number = 0;
	idToken: string = "";
	firstIssuedAt: number = 0;
	idpId: string = "";
	tokenType: string = "";
	refreshToken: string = "";
	
	fromDTO(t: GoogleOAuthTokenDTO): void | IUIError {
		this.accessToken = t.getAccessToken();
		this.expiresAt = t.getExpiresAt();
		this.expiresIn = t.getExpiresIn();
		this.firstIssuedAt = t.getFirstIssuedAt();
		this.idToken = t.getIdToken();
		this.idpId = t.getIdpid();
		this.tokenType = t.getTokenType();
		this.refreshToken = t.getRefreshToken();
		
		return;
	}
	
	static fromJSON(t: IGoogleOAuthToken): GoogleOAuthToken {
		let token = new GoogleOAuthToken();
		token.accessToken = t.accessToken;
		token.expiresAt = t.expiresAt;
		token.expiresIn = t.expiresIn;
		token.idToken = t.idToken;
		token.firstIssuedAt = t.firstIssuedAt;
		token.idpId = t.idpId;
		token.tokenType = t.tokenType;
		token.refreshToken = t.refreshToken;
		
		return token
	}
	
	intoDTO(): GoogleOAuthTokenDTO | IUIError {
		let dto = new GoogleOAuthTokenDTO();
		dto.setAccessToken(this.accessToken);
		dto.setExpiresAt(this.expiresAt);
		dto.setExpiresIn(this.expiresIn);
		dto.setFirstIssuedAt(this.firstIssuedAt);
		dto.setIdToken(this.idToken);
		dto.setIdpid(this.idpId);
		dto.setTokenType(this.tokenType);
		dto.setRefreshToken(this.refreshToken);
		
		return dto;
	}
	
	customValidate(): IUIError | GoogleOAuthToken {
		if (this.accessToken === "") {
			return NewUIErrorV2(ActionType.Validate, EntityKind.Auth, "accesstoken is empty")
		}
		
		return this
	}
	
	sanitize(): IUIError | GoogleOAuthToken {
		return this
	}
}

export interface IGoogleAdditionalUserInfo {
	isNewUser: boolean,
	providerId: string,
	profile: {
		email: string,
		family_name: string,
		given_name: string,
		id: string,
		locale: string,
		name: string,
		picture: string,
		verified_email: boolean
	}
}

export interface IGoogleOAuthCredential {
	accessToken: string,
	idToken: string,
	providerId: string,
	signInMethod: string,
	token: string
}

interface  IGenericFederatedProfile {
	providerId: string,
	email: string,
	name: string,
	familyName: string,
	givenName: string,
	imageUrl: string,
	locale: string,
	isVerified: boolean,
	isNewUser: boolean,
	provider: FederatedProviderDTO
}

export class GenericFederatedProfile implements IFromDTO<SignUpProfileDTO>, IValidator<GenericFederatedProfile> {
	providerId: string = "";
	email: string = "";
	familyName: string = "";
	name: string = "";
	givenName: string = "";
	imageUrl: string = "";
	locale: string = "";
	isVerified: boolean = false;
	isNewUser: boolean = false;
	provider: FederatedProviderDTO = FederatedProviderDTO.GOOGLE
	
	fromDTO(t: SignUpProfileDTO): void | IUIError {
		this.providerId = t.getProviderid();
		this.email = t.getEmail();
		this.familyName = t.getFamilyname();
		this.name = t.getName();
		this.givenName = t.getGivenname();
		this.imageUrl = t.getImageurl();
		this.provider = t.getProvider();
		return;
	}
	
	static fromJSON(t: IGenericFederatedProfile): GenericFederatedProfile {
		let profile = new GenericFederatedProfile();
		profile.providerId = t.providerId;
		profile.email = t.email;
		profile.familyName = t.familyName;
		profile.name = t.name;
		profile.givenName = t.givenName;
		profile.imageUrl = t.imageUrl;
		profile.locale = t.locale;
		profile.isVerified = t.isVerified;
		profile.provider = t.provider;
		profile.isNewUser = t.isNewUser;
		
		return profile
	}
	
	intoDTO(): IUIError | SignUpProfileDTO {
		let dto = new SignUpProfileDTO();
		dto.setProviderid(this.providerId);
		dto.setEmail(this.email);
		dto.setFamilyname(this.familyName);
		dto.setName(this.name);
		dto.setGivenname(this.givenName);
		dto.setImageurl(this.imageUrl);
		dto.setLocale(this.locale);
		dto.setIsnewuser(this.isNewUser);
		dto.setIsverified(this.isVerified);
		dto.setProvider(this.provider);
		
		return dto;
	}
	
	customValidate(): IUIError | GenericFederatedProfile {
		if (this.providerId === "") {
			return NewUIErrorV2(ActionType.Validate, EntityKind.Auth, "googleid is empty")
		}
		if (this.email === "") {
			return NewUIErrorV2(ActionType.Validate, EntityKind.Auth, "email is empty")
		}
		
		return this
	}
	
	sanitize(): IUIError | GenericFederatedProfile {
		return this
	}
	
}

interface InfoRequest {
	token_id: string
	profile: GenericFederatedProfile,
	token: GoogleOAuthToken
}