import {ProgressStateEnumDTO, UUID_DTO} from "proto/utils_pb";
import {InternalErrorTypes, isError, IUIError, NewUIError,} from "service/cartaError";
import {
    convertDateToTimestamp, convertFromDTOToDate,
    convertFromDTOToID,
    convertOptTimestampToDate,
    convertTimestampToDate,
    ListItem,
} from "utils/utils";
import {ReviewSM2CardDTO, ReviewSM2DTO} from "proto/reviewSM2_pb";
import {getUserId} from "service/AuthService";
import {ReviewSM2Card, ReviewSM2CardStat} from "./ReviewSM2Card";
import {IFromDTO, IReceiveOnlyModel} from "./model";
import {ReviewSM2StatDTO} from "proto/stats_pb";
import {IReview, IReviewStat} from "./Review";
import {BaseModel, EntityKind} from "./BaseModel";
import {IDisplayItem} from "./interfaces";
import { ReviewKindEnumDTO } from "proto/reviewManual_pb";

export class ReviewSM2
    extends BaseModel<ReviewSM2, ReviewSM2DTO>
    implements IReview, IFromDTO<ReviewSM2DTO> {
    name?: string;
    description?: string;
    cards: ReviewSM2Card[];
    kind: ReviewKindEnumDTO = ReviewKindEnumDTO.SM2;
    progressState: ProgressStateEnumDTO;
    note: string;
    startAt?: Date;
    endAt?: Date;
    createdOn: Date;
    updatedOn: Date;
    archivedOn?: Date;
    
    constructor() {
        super();
        const now = this.getCurrentDate();
        
        this.name = undefined;
        this.description = undefined;
        this.note = "";
        this.startAt = undefined;
        this.endAt = undefined;
        this.createdOn = now;
        this.updatedOn = now;
        this.archivedOn = undefined;
        this.cards = [];
        this.progressState = ProgressStateEnumDTO.NOT_STARTED;
    }
    
    getCurrentDate(): Date {
        return new Date();
    }
    
    toListItem(): ListItem {
        return {
            id: this.id,
            title: this.name ? this.name : `SM2 Review: ${this.createdOn.toString()}`,
            metadata1: this.description,
        };
    }
    
    toDisplayable(): IDisplayItem {
        throw new Error("Method not implemented.");
    }
    
    to1LineString(): string {
        throw new Error("Method not implemented.");
    }
    
    init(): ReviewSM2 {
        return new ReviewSM2();
    }
    
    TYPE: EntityKind = EntityKind.ReviewSM2;
    
    intoDTO(): ReviewSM2DTO | IUIError {
        const dto = new ReviewSM2DTO();
        
        dto.setId(new UUID_DTO().setValue(this.id));
        dto.setUserId(new UUID_DTO().setValue(this.userId));
        dto.setCreatedon(convertDateToTimestamp(this.createdOn));
        dto.setUpdatedon(convertDateToTimestamp(this.updatedOn));
        dto.setNote(this.note);
        dto.setProgressState(this.progressState);
        
        if (this.name) {
            dto.setName(this.name);
        }
        if (this.description) {
            dto.setDescription(this.description);
        }
        if (this.endAt) {
            dto.setEndAt(convertDateToTimestamp(this.endAt));
        }
        if (this.startAt) {
            dto.setStartAt(convertDateToTimestamp(this.startAt));
        }
        if (this.archivedOn) {
            dto.setArchivedon(convertDateToTimestamp(this.archivedOn));
        }
        
        return dto;
    }
    
    customValidate(): ReviewSM2 | IUIError {
        if (this.name && this.name.length > 100) {
            return NewUIError(
                "validate",
                InternalErrorTypes.ValidateReview,
                "name field > 100",
                "name cannot be greater than 100 characters"
            );
        }
        
        if (this.description && this.description.length > 250) {
            return NewUIError(
                "validate",
                InternalErrorTypes.ValidateReview,
                "description field > 250",
                "description cannot be greater than 100 characters"
            );
        }
        
        return this;
    }
    
    sanitize(): ReviewSM2 {
        this.note = this.note.trim();
        if (this.name) {
            this.name = this.name.trim();
        }
        if (this.description) {
            this.description = this.description.trim();
        }
        return this;
    }
    
    fromDTO(dto: ReviewSM2DTO): void | IUIError {
        this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
        this.userId = convertFromDTOToID('userId', this.TYPE, dto.getUserId());
        this.createdOn = convertFromDTOToDate('createdOn', this.TYPE, dto.getCreatedon())!
        this.updatedOn = convertFromDTOToDate('updatedOn', this.TYPE, dto.getUpdatedon())!
        this.startAt = convertFromDTOToDate('startAt', this.TYPE, dto.getStartAt(), true)
        this.endAt = convertFromDTOToDate('endAt', this.TYPE, dto.getEndAt(), true)
        this.archivedOn = convertFromDTOToDate('archivedOn', this.TYPE, dto.getArchivedon(), true)
        this.cards = [];
        this.kind = ReviewKindEnumDTO.SM2;
        this.description = dto.getDescription();
        this.name = dto.getName();
        this.note = dto.getNote();
        this.progressState = dto.getProgressState();
    }
    
    static init = (userId: string): ReviewSM2 => {
        const now = ReviewSM2.prototype.getCurrentDate();
        
        const review = new ReviewSM2();
        review.userId = userId;
        review.progressState = ProgressStateEnumDTO.NOT_STARTED;
        review.updatedOn = now;
        review.kind = ReviewKindEnumDTO.SM2;
        review.sanitize();
        
        return review;
    };
    
    convertReviewSM2ToDTO = (): ReviewSM2DTO => {
        const dto = new ReviewSM2DTO();
        dto.setId(new UUID_DTO().setValue(this.id));
        dto.setUserId(new UUID_DTO().setValue(this.userId));
        dto.setCreatedon(convertDateToTimestamp(this.createdOn));
        dto.setUpdatedon(convertDateToTimestamp(this.updatedOn));
        dto.setNote(this.note);
        dto.setProgressState(this.progressState);
        
        if (this.name) {
            dto.setName(this.name);
        }
        if (this.description) {
            dto.setDescription(this.description);
        }
        if (this.endAt) {
            dto.setEndAt(convertDateToTimestamp(this.endAt));
        }
        if (this.startAt) {
            dto.setStartAt(convertDateToTimestamp(this.startAt));
        }
        if (this.archivedOn) {
            dto.setArchivedon(convertDateToTimestamp(this.archivedOn));
        }
        
        const dtos: ReviewSM2CardDTO[] = [];
        
        this.cards.forEach((card) => {
            const dto = card.intoDTO();
            if (isError(dto)) {
                return dto as IUIError;
            }
            
            dtos.push(dto as ReviewSM2CardDTO);
        });
        
        return dto;
    };
    
    clone(): ReviewSM2 {
        const newItem = new ReviewSM2();
        
        newItem.name = this.name;
        newItem.id = this.id;
        newItem.userId = this.userId;
        newItem.description = this.description;
        newItem.kind = this.kind;
        newItem.progressState = this.progressState;
        newItem.note = this.note;
        
        newItem.startAt = this.startAt ? new Date(this.startAt.getTime()) : undefined;
        newItem.endAt = this.endAt ? new Date(this.endAt.getTime()) : undefined;
        newItem.archivedOn = this.archivedOn ? new Date(this.archivedOn.getTime()) : undefined;
        newItem.createdOn = new Date(this.createdOn.getTime());
        newItem.updatedOn = new Date(this.updatedOn.getTime());
        
        return newItem;
    }
}

export class ReviewSM2Stat
    implements IReceiveOnlyModel<ReviewSM2Stat, ReviewSM2StatDTO>, IReviewStat {
    TYPE: EntityKind = EntityKind.ReviewSM2Stat;
    
    id: string = "";
    reviewId: string = "";
    strongestCards: string[] = [];
    weakestCards: string[] = [];
    avgCompletedQuality?: number;
    avgCompletedInterval?: number;
    avgCompletedRepetitions?: number;
    avgQuality?: number;
    avgInterval?: number;
    avgRepetition?: number;
    mostRepeatedCard?: string;
    reviewCards: ReviewSM2CardStat[] = [];

    clone(): ReviewSM2Stat {
        let stat = new ReviewSM2Stat();

        stat.id = this.id;
        stat.strongestCards = [...this.strongestCards];
        stat.weakestCards = [...this.weakestCards];
        stat.avgCompletedQuality = this.avgCompletedQuality;
        stat.avgCompletedInterval = this.avgCompletedInterval;
        stat.avgCompletedRepetitions = this.avgCompletedRepetitions;
        stat.avgQuality = this.avgQuality;
        stat.avgInterval = this.avgInterval;
        stat.avgRepetition = this.avgRepetition;
        stat.mostRepeatedCard = this.mostRepeatedCard;
        if (this.reviewCards) {
            stat.reviewCards = [...this.reviewCards];
        }

        return stat;
    }

    fromDTO(review: ReviewSM2StatDTO): void | IUIError {
        if (review.getId()) {
            if (review.getId()!.getValue()) {
                this.id = review.getId()!.getValue();
            } else {
                return NewUIError(
                    "fromDTO",
                    InternalErrorTypes.InvalidReviewCard,
                    `getId is empty '' - reviewCard: ${review}"`
                );
            }
        } else {
            return NewUIError(
                "fromDTO",
                InternalErrorTypes.InvalidReviewCard,
                `getId is undefined '' - reviewCard: ${review}"`
            );
        }

        if (review.getMostrepeatedcard()) {
            this.mostRepeatedCard = review.getMostrepeatedcard()!.getValue();
        } else {
            // return NewUIError(
            //   "fromDTO",
            //   InternalErrorTypes.InvalidReviewCard,
            //   `getMostrepeatedcard is undefined '' - ${review}"`
            // );
        }

        this.strongestCards = review
            .getStrongestcardsList()
            .map((x) => x.getValue());
        this.weakestCards = review.getWeakestcardsList().map((x) => x.getValue());
        this.avgCompletedRepetitions = review.getAvgcompletedrepetition();
        this.avgRepetition = review.getAvgrepetition();
        this.avgQuality = review.getAvgquality();
        this.avgCompletedQuality = review.getAvgcompletedquality();
        this.avgCompletedInterval = review.getAvgcompletedinterval();
        this.avgInterval = review.getAvginterval();
        this.reviewCards = review.getReviewcardstatsList().map((x) => {
            let stat = new ReviewSM2CardStat();
            stat.fromDTO(x);
            return stat;
        });
    }
}

// Create a function that creates a ReviewSM2 object with dummy data, that takes in a userId and randomly generates
// short strings for the other string data
