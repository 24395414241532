import {
    ActionType,
    InternalErrorTypes,
    IUIError,
    LogErrorToProvider,
    NewUIError,
    NewUIErrorV2
} from "../service/cartaError";
import {
    convertDateToTimestamp,
    convertFromDTOToDate,
    convertFromDTOToID,
    dateTimeToRelativeV2,
    ListItem,
    NewUUID,
} from "../utils/utils";
import {ReviewManualCard, ReviewManualCardStat} from "./ReviewManualCard";
import {ProgressStateEnumDTO, UUID_DTO} from "../proto/utils_pb";
import {ReviewKindEnumDTO, ReviewManualDTO,} from "../proto/reviewManual_pb";
import {getUserId} from "../service/AuthService";
import {IModel, IReceiveOnlyModel} from "./model";
import {ReviewManualStatDTO} from "../proto/stats_pb";
import {IReview, IReviewStat} from "./Review";
import {BaseModel, EntityKind} from "./BaseModel";
import {IDisplayItem} from "./interfaces";
import {GridColDef, GridRowModel, GridValidRowModel} from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import {Card} from "./Card";
import React from "react";

export class Fetchable<T extends IModel> {
    private _model: T;
    private _lastFetched?: Date;
    
    constructor(obj: T) {
        this._model = obj
    }
    
    get model(): T {
        return this._model;
    }
    
    set model(value: T) {
        this._model = value;
    }
    
    get lastFetched(): Date | undefined {
        return this._lastFetched;
    }
    
    set lastFetched(value: Date | undefined) {
        this._lastFetched = value;
    }
}

export class ReviewManual extends BaseModel<ReviewManual, ReviewManualDTO> implements IReview {
    name?: string;
    description?: string;
    note?: string;
    cards: ReviewManualCard[];
    progressState: ProgressStateEnumDTO;
    currentCard: string;
    configId: string;
    kind: ReviewKindEnumDTO;
    startAt?: Date;
    endAt?: Date;
    archivedOn?: Date;
    cardOrderList: string[] = []
    
    constructor() {
        super();
        this.kind = ReviewKindEnumDTO.MANUAL;
        this.currentCard = "";
        this.note = "";
        this.progressState = ProgressStateEnumDTO.NOT_STARTED;
        this.cards = [];
        this.configId = ""
    }
    
    toListItem(): ListItem {
        return {
            id: this.id,
            title: this.name ? this.name : `Review: ${this.createdOn.toString()}`,
            metadata1: this.description,
        }
    }
    
    static fromConfig = (configId: string): ReviewManual => {
        let review = new ReviewManual();
        review.configId = configId;
        
        return review;
    }
    
    toDisplayable(): IDisplayItem {
        return {
            id: this.id,
            title: this.name ? this.name : "Untitled",
            description: this.description,
        };
    }
    
    to1LineString(): String {
        return `${this.name} - ${this.description}`;
    }
    
    init(): ReviewManual {
        return new ReviewManual()
    }
    
    TYPE: EntityKind = EntityKind.ReviewManual;
    
    clone(): ReviewManual {
        const review = new ReviewManual();
        
        // Use spread operator and direct assignments for primitive and reference types.
        review.cards = [...this.cards];
        review.id = this.id;
        review.configId = this.configId;
        review.userId = this.userId;
        review.name = this.name;
        review.description = this.description;
        review.progressState = this.progressState;
        review.currentCard = this.currentCard;
        review.cardOrderList = [...this.cardOrderList];
        review.note = this.note;
        review.kind = this.kind;
        review.startAt = this.startAt;
        review.endAt = this.endAt;
        review.createdOn = this.createdOn;
        review.updatedOn = this.updatedOn;
        review.archivedOn = this.archivedOn;
        
        return review;
    }
    
    fromDTO = (dto: ReviewManualDTO): void | IUIError => {
        this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
        this.userId = convertFromDTOToID('userId', this.TYPE, dto.getUserid());
        if (dto.hasReviewconfigid()) {
            this.configId = convertFromDTOToID('configId', this.TYPE, dto.getReviewconfigid()!);
        } else {
            LogErrorToProvider(NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "config_id field empty", "config_id cannot be empty"))
        }
        this.name = dto.getName();
        this.description = dto.getDescription();
        this.note = dto.getNote();
        this.progressState = dto.getProgressState();
        this.kind = ReviewKindEnumDTO.MANUAL;
        this.startAt = convertFromDTOToDate('startAt', this.TYPE, dto.getStartAt(), true);
        this.endAt = convertFromDTOToDate('endAt', this.TYPE, dto.getEndAt(), true);
        this.archivedOn = convertFromDTOToDate('archivedOn', this.TYPE, dto.getArchivedon(), true);
        this.createdOn = convertFromDTOToDate('createdOn', this.TYPE, dto.getCreatedon(), false)!;
        this.updatedOn = convertFromDTOToDate('updatedOn', this.TYPE, dto.getUpdatedon())!;
        this.cardOrderList = dto.getCardOrderListList().map((x) => x.getValue())
        if (this.cardOrderList.length === 0) {
            LogErrorToProvider(NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "cardOrderList field empty", "cardOrderList cannot be empty"))
        }
        if (dto.hasCurrentCardId()) {
            this.currentCard = convertFromDTOToID('currentCard', EntityKind.ReviewManualCard, dto.getCurrentCardId()!);
        } else {
            if (this.cardOrderList.length > 0) {
                this.currentCard = this.cardOrderList[0]
            } else {
                LogErrorToProvider(NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, `reviewId: ${this.id} has no currentCardId or cardOrderList ... defaulting to first`))
            }
        }
    };
    
    customValidate = (): ReviewManual | IUIError => {
        if (this.name && this.name?.length > 100) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "name field > 100", "name cannot be greater than 100 characters")
        }
        if (this.description && this.description?.length > 250) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "description field > 250", "description cannot be greater than 100 characters")
        }
        if (this.cards.length > 100) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "cards field > 100", "Cannot have more than 100 cards in a review. Split your reviews.")
        }
        if (this.configId.length === 0) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "config_id field empty", "config_id cannot be empty")
        }
        
        return this.sanitize()
    };
    
    sanitize = (): ReviewManual => {
        this.name = this.name?.trim();
        this.description = this.description?.trim();
        this.note = this.note?.trim();
        
        return this
    };
    
    intoDTO = (): ReviewManualDTO | IUIError => {
        let dto = new ReviewManualDTO();
        
        dto.setId(new UUID_DTO().setValue(this.id));
        dto.setUserid(new UUID_DTO().setValue(this.userId));
        dto.setReviewconfigid(new UUID_DTO().setValue(this.configId));
        
        if (this.name) {
            dto.setName(this.name);
        }
        if (this.description) {
            dto.setDescription(this.description);
        }
        
        dto.setNote(this.note ? this.note : "");
        dto.setProgressState(this.progressState);
        
        
        if (this.currentCard) {
            dto.setCurrentCardId(new UUID_DTO().setValue(this.currentCard));
        }
        
        dto.setCardOrderListList(this.cardOrderList.map((id) => {return new UUID_DTO().setValue(id)
        }))
        
        if (this.endAt) {
            dto.setEndAt(convertDateToTimestamp(this.endAt));
        }
        if (this.startAt) {
            dto.setStartAt(convertDateToTimestamp(this.startAt));
        }
        if (this.archivedOn) {
            dto.setArchivedon(convertDateToTimestamp(this.archivedOn));
        }
        
        dto.setCreatedon(convertDateToTimestamp(this.createdOn));
        dto.setUpdatedon(convertDateToTimestamp(this.updatedOn));
        
        return dto;
    };
}

export class ReviewManualStat implements IReceiveOnlyModel<ReviewManualStat, ReviewManualStatDTO>, IReviewStat {
    id: string = "";
    reviewId: string = "";
    userId: string = "";
    strongestCards: string[] = [];
    weakestCards: string[] = [];
    avgCompletedQuality?: number;
    avgCompletedInterval?: number;
    avgCompletedRepetitions?: number;
    avgQuality?: number;
    avgInterval?: number;
    avgRepetition?: number;
    mostRepeatedCard?: string;
    reviewCards?: ReviewManualCardStat[] = [];
    
    constructor() {
    }
    
    TYPE: EntityKind = EntityKind.ReviewManualStat;
    
    clone(): ReviewManualStat {
        let stat = new ReviewManualStat();
        stat.id = this.id;
        stat.strongestCards = [...this.strongestCards];
        stat.weakestCards = [...this.weakestCards];
        stat.avgCompletedQuality = this.avgCompletedQuality;
        stat.avgCompletedInterval = this.avgCompletedInterval;
        stat.avgCompletedRepetitions = this.avgCompletedRepetitions;
        stat.avgQuality = this.avgQuality;
        stat.avgInterval = this.avgInterval;
        stat.avgRepetition = this.avgRepetition;
        stat.mostRepeatedCard = this.mostRepeatedCard;
        if (this.reviewCards) {
            stat.reviewCards = [...this.reviewCards];
        }
        
        return stat;
    }
    
    fromDTO(review: ReviewManualStatDTO): void | IUIError {
        this.id = convertFromDTOToID('id', this.TYPE, review.getId());
        
        this.strongestCards = review
            .getStrongestcardsList()
            .map((x) => x.getValue());
        this.weakestCards = review.getWeakestcardsList().map((x) => x.getValue());
        this.avgCompletedRepetitions = review.getAvgcompletedrepetition();
        this.avgRepetition = review.getAvgrepetition();
        this.avgQuality = review.getAvgquality();
        this.avgCompletedQuality = review.getAvgcompletedquality();
        this.avgCompletedInterval = review.getAvgcompletedinterval();
        this.avgInterval = review.getAvginterval();
        this.reviewCards = review.getReviewcardstatsList().map((x) => {
            let stat = new ReviewManualCardStat();
            stat.fromDTO(x);
            return stat;
        });
    }
}


export const ColReviewManualStandard: GridColDef<GridValidRowModel>[] = [
    {
        field: 'topics',
        headerName: 'Topics',
        type: 'string',
    },
    {
        field: 'card_front',
        headerName: 'Card',
        maxWidth: 400,
        // maxWidth: 600,
        type: 'string',
        filterable: true,
        editable: true,
    },
    {
        field: 'confidence',
        headerName: 'Confidence',
        minWidth: 300,
        type: 'number'
    },
    {
        field: 'previousConfidence',
        headerName: 'Previous Confidence',
        width: 150,
        type: 'number'
    },
    {
        field: 'tags',
        headerName: 'Tags',
        display: 'flex',
        hideable: true,
        type: 'string',
    },
    {
        field: 'duration',
        headerName: '',
        width: 150,
        type: 'number'
    },
]


export const RowUpdateReviewManualStandard = (item: ReviewManualCard, row: GridRowModel): ReviewManualCard => {
    let newCard = item.clone()
    newCard.composite.card.front = row.card_front as string ? row.card_front as string : newCard.composite.card.front
    newCard.updatedOn = new Date()
    
    return newCard;
}

export const RowCreatorReviewManualStandard = (item: ReviewManualCard): GridRowModel => {
    const composite = item.composite.card
    if (composite === undefined) {
        console.log("composite is undefined")
        return {
            id: item.id,
            topics: "",
            card_front: item.composite.card,
            tags: [],
            resource: "",
            createdOn: dateTimeToRelativeV2(item.createdOn),
            confidence: 0,
        }
    }
    
    const cardComposite = item.composite.card.composite
    if (cardComposite === undefined) {
        console.log("cardComposite is undefined")
        return {
            id: item.id,
            topics: "",
            card_front: item.composite.card.front,
            tags: [],
            // resource: "",
            createdOn: dateTimeToRelativeV2(item.createdOn),
            confidence: 0,
        }
    }
    
    console.log("listItem: ", item)
    
    return {
        id: item.id,
        topics: cardComposite.topics.map((topic) => topic.topic).join(", "),
        card_front: composite.front,
        tags: cardComposite.tags.map((tag) => tag.tag),
        // resource: cardComposite.resources.map((resource) => resource.title).join(", "),
        createdOn: dateTimeToRelativeV2(item.createdOn),
        confidence: 0,
    }
}