import {ActionType, InternalErrorTypes, isError, IUIError, NewUIError, NewUIErrorV2,} from "../service/cartaError";
import {Card} from "./Card";
import {
    convertDateToTimestamp,
    convertFromDTOToDate,
    convertFromDTOToID,
    convertTimestampToDate,
    ListItem,
} from "../utils/utils";
import {EnumCardKindDTO, UUID_DTO} from "../proto/utils_pb";
import {ReviewManualCardCompositeDTO, ReviewManualCardDTO} from "../proto/reviewManual_pb";
import {IFromDTO, IIntoDTO} from "./model";
import {ReviewManualCardStatDTO} from "../proto/stats_pb";
import {IReviewCard} from "./Review";
import {BaseModel, EntityKind} from "./BaseModel";
import {IDisplayItem} from "./interfaces";
import {ICard} from "./CardComposite";
import {IReviewCardComposite} from "./ReviewSM2Card";
import {CardLang} from "./CardLang";
import {CardDTO} from "../proto/card_pb";
import {CardLangDTO} from "../proto/cardLang_pb";

export class ReviewManualCard extends BaseModel<ReviewManualCard, ReviewManualCardDTO> implements IReviewCard {
    init(): ReviewManualCard {
        return new ReviewManualCard()
    }
    
    get cardKind(): EnumCardKindDTO {
        return this._cardKind;
    }
    
    toListItem(): ListItem {
        return {
            id: this.id,
            title: this._composite.card.front,
            metadata1: this._composite.card.back,
        }
    }
    
    set cardKind(value: EnumCardKindDTO) {
        this._cardKind = value;
    }
    
    private _reviewId: string;
    private _card_id: string;
    private _quality?: number;
    private _cardKind: EnumCardKindDTO = EnumCardKindDTO.CARDKIND_LANGUAGE;
    private _startAt?: Date;
    private _endAt?: Date;
    private _composite: ReviewManualCardComposite = new ReviewManualCardComposite()
    
    constructor() {
        super();
        
        this._reviewId = "";
        this._card_id = "";
    }
    
    toDisplayable(): IDisplayItem {
        return {
            id: this.id,
            title: this._card_id,
        }
    }
    
    to1LineString(): String {
        return this.id
    }
    
    static init(card: ICard): ReviewManualCard {
        let reviewCard = new ReviewManualCard();
        reviewCard.cardId = card.id
        
        switch (card.TYPE) {
            case EntityKind.Card:
                reviewCard._cardKind = EnumCardKindDTO.CARDKIND_STANDARD;
                break;
            case EntityKind.CardLang:
                reviewCard._cardKind = EnumCardKindDTO.CARDKIND_LANGUAGE;
                break;
            default:
                reviewCard._cardKind = EnumCardKindDTO.CARDKIND_STANDARD;
        }
        
        let composite = new ReviewManualCardComposite();
        composite.setup(card, reviewCard.id, 0)
        
        reviewCard.composite = composite;
        
        return reviewCard
    }
    
    TYPE: EntityKind = EntityKind.ReviewManualCard;
    
    fromDTO = (dto: ReviewManualCardDTO): void | IUIError => {
        this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
        this.userId = convertFromDTOToID('userId', this.TYPE, dto.getUserid());
        this.cardId = convertFromDTOToID('cardId', this.TYPE, dto.getCardid());
        this.reviewId = convertFromDTOToID('reviewId', this.TYPE, dto.getReviewid());
        this.createdOn = convertFromDTOToDate('createdOn', this.TYPE, dto.getCreatedon(), false)!;
        this.updatedOn = convertFromDTOToDate('updatedOn', this.TYPE, dto.getUpdatedon())!;
        this.startAt = convertFromDTOToDate('startAt', this.TYPE, dto.getStartAt(), true)
        this.endAt = convertFromDTOToDate('endAt', this.TYPE, dto.getEndAt(), true)
        this._quality = dto.getQuality();
        
        let composite = new ReviewManualCardComposite();
        let dto_composite = dto.getComposite()
        
        if (dto_composite) {
            composite.fromDTO(dto_composite);
        }
        this.composite = composite;
    };
    
    intoDTO(): IUIError | ReviewManualCardDTO {
        let dto: ReviewManualCardDTO = new ReviewManualCardDTO();
        
        const validate = this.customValidate();
        if (isError(validate)) {
            return validate as IUIError
        }
        const valid = validate as ReviewManualCard
        
        if (this._composite) {
            const composite = this._composite.intoDTO()
            if (isError(composite)) {
                return composite as IUIError
            }
            
            dto.setComposite(composite as ReviewManualCardCompositeDTO)
        }
        
        dto.setId(new UUID_DTO().setValue(valid.id));
        dto.setCardid(new UUID_DTO().setValue(valid._card_id));
        dto.setReviewid(new UUID_DTO().setValue(valid._reviewId));
        dto.setUserid(new UUID_DTO().setValue(valid.userId));
        
        if (this._quality !== undefined) {
            dto.setQuality(this._quality);
        }
        if (this._startAt) {
            dto.setStartAt(convertDateToTimestamp(this._startAt));
        }
        if (this._endAt) {
            dto.setEndAt(convertDateToTimestamp(this._endAt));
        }
        dto.setCreatedon(convertDateToTimestamp(valid.createdOn));
        dto.setUpdatedon(convertDateToTimestamp(valid.updatedOn));
        
        return dto;
    }
    
    clone(): ReviewManualCard {
        let clonedCard = new ReviewManualCard()
        
        clonedCard.id = this.id;
        clonedCard.userId = this.userId;
        clonedCard.cardId = this.cardId;
        clonedCard.reviewId = this.reviewId;
        clonedCard.quality = this.quality;
        clonedCard.startAt = this.startAt;
        clonedCard.endAt = this.endAt;
        clonedCard.composite = this.composite;
        clonedCard.createdOn = this.createdOn;
        clonedCard.updatedOn = this.updatedOn;
        
        return clonedCard
    }
    
    sanitize(): ReviewManualCard {
        return this;
    }
    
    customValidate(): IUIError | ReviewManualCard {
        if (this._reviewId === "") {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "ReviewID cannot be empty")
        }
        
        if (this.quality === undefined) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "Quality cannot be undefined", "Quality needs to be specified")
        }
        
        return this
    }
    
    get composite(): ReviewManualCardComposite {
        return this._composite;
    }
    
    set composite(value: ReviewManualCardComposite) {
        this._composite = value;
    }
    
    get reviewId(): string {
        return this._reviewId;
    }
    
    set reviewId(value: string) {
        this._reviewId = value;
    }
    
    get quality(): number | undefined {
        return this._quality;
    }
    
    set quality(value: number | undefined) {
        this._quality = value;
    }
    
    get startAt(): Date | undefined {
        return this._startAt;
    }
    
    set startAt(value: Date | undefined) {
        this._startAt = value;
    }
    
    get endAt(): Date | undefined {
        return this._endAt;
    }
    
    set endAt(value: Date | undefined) {
        this._endAt = value;
    }
    
    get cardId(): string {
        return this._card_id;
    }
    
    set cardId(value: string) {
        this._card_id = value;
    }
}

export class ReviewManualCardComposite implements IReviewCardComposite<ICard>,
    IIntoDTO<ReviewManualCardCompositeDTO>, IFromDTO<ReviewManualCardCompositeDTO> {
    get id(): string {
        return this._id;
    }
    
    set id(value: string) {
        this._id = value;
    }
    
    get ord(): number {
        return this._ord;
    }
    
    set ord(value: number) {
        this._ord = value;
    }
    
    private _card: ICard;
    private _id: string = "";
    private _ord: number = 0;
    
    constructor() {
        const now = new Date();
        this._card = new Card();
    }
    
    setup(card: ICard, id: string, ord: number) {
        this._card = card;
        this._id = id;
        this._ord = ord;
    }
    
    init(): IReviewCardComposite<ICard> {
        return new ReviewManualCardComposite()
    }
    
    toDisplayable(): IDisplayItem {
        return {
            id: this.id,
            title: this._card.front,
        }
    }
    
    to1LineString(): String {
        return this.id
    }
    
    TYPE: EntityKind = EntityKind.ReviewManualCardComposite;
    
    fromDTO(t: ReviewManualCardCompositeDTO): void | IUIError {
        let card: ICard;
        if (t.getCard()) {
            card = new Card()
            card.fromDTO(t.getCard()!)
        } else {
            card = new CardLang()
            if (t.getCardLang()) {
                card.fromDTO(t.getCardLang()!)
            } else {
                return NewUIError(
                    "fromDTO",
                    InternalErrorTypes.InvalidReviewCard,
                    `card and cardLang is undefined '' - reviewManualCard: ${t}"`
                );
            }
        }
        
        let id = convertFromDTOToID('id', EntityKind.ReviewManualCardComposite, t.getSupertypeId())
        
        this._card = card
        this.id = id as string;
    }
    
    
    intoDTO(): IUIError | ReviewManualCardCompositeDTO {
        let dto = new ReviewManualCardCompositeDTO();
        
        switch (this._card.TYPE) {
            case EntityKind.Card:
                dto.setCard(this._card.intoDTO() as CardDTO)
                break;
            case EntityKind.CardLang:
                dto.setCardLang(this._card.intoDTO() as CardLangDTO)
                break;
            default:
                return NewUIError(
                    "intoDTO",
                    InternalErrorTypes.InvalidReviewCard,
                    `card is undefined '' - reviewManualCard: ${this}"`
                );
        }
        
        dto.setSupertypeId(new UUID_DTO().setValue(this.id))
        
        return dto
    }
    
    
    // get ord(): number {
    // 	return this.ord;
    // }
    //
    // set ord(value: number) {
    // 	this.ord = value;
    // }
    
    get card(): ICard {
        return this._card;
    }
    
    set card(value: ICard) {
        this._card = value;
    }
}

export class ReviewManualCardStat implements IFromDTO<ReviewManualCardStatDTO> {
    id: string = "";
    cardId: string = "";
    reviewId: string = "";
    quality?: number;
    interval?: number;
    repetitions?: number;
    endAt?: Date;
    startAt?: Date;
    
    fromDTO(reviewCard: ReviewManualCardStatDTO): void | IUIError {
        if (reviewCard.getId()) {
            if (reviewCard.getId()!.getValue()) {
                this.id = reviewCard.getId()!.getValue();
            } else {
                return NewUIError(
                    "fromDTO",
                    InternalErrorTypes.InvalidReviewCard,
                    `getId is empty '' - reviewCard: ${reviewCard}"`
                );
            }
        } else {
            return NewUIError(
                "fromDTO",
                InternalErrorTypes.InvalidReviewCard,
                `getId is undefined '' - reviewCard: ${reviewCard}"`
            );
        }
        
        if (reviewCard.getCardid()) {
            if (reviewCard.getCardid()!.getValue()) {
                this.cardId = reviewCard.getCardid()!.getValue();
            } else {
                return NewUIError(
                    "fromDTO",
                    InternalErrorTypes.InvalidReviewCard,
                    `getCardid is empty '' - reviewCard: ${reviewCard}"`
                );
            }
        } else {
            return NewUIError(
                "fromDTO",
                InternalErrorTypes.InvalidReviewCard,
                `getCardid is undefined '' - reviewCard: ${reviewCard}"`
            );
        }
        
        if (reviewCard.getReviewid()) {
            if (reviewCard.getReviewid()!.getValue()) {
                this.reviewId = reviewCard.getReviewid()!.getValue();
            } else {
                return NewUIError(
                    "fromDTO",
                    InternalErrorTypes.InvalidReviewCard,
                    `getReviewid is empty '' - reviewCard: ${reviewCard}"`
                );
            }
        } else {
            return NewUIError(
                "fromDTO",
                InternalErrorTypes.InvalidReviewCard,
                `getReviewid is undefined '' - reviewCard: ${reviewCard}"`
            );
        }
        
        this.repetitions = reviewCard.getRepetitions();
        this.quality = reviewCard.getQuality();
        this.interval = reviewCard.getInterval();
        if (reviewCard.getStartat()) {
            this.startAt = convertTimestampToDate(reviewCard.getStartat()!);
        }
        if (reviewCard.getEndat()) {
            this.endAt = convertTimestampToDate(reviewCard.getEndat()!);
        }
    }
}

// export interface ReviewManualCard {
//     userId: string,
//     reviewId: string,
//     card: Card,
//     stats?: ReviewCardStats
//     createdOn: Date,
//     updatedOn: Date,
// }
//
// export const ReviewCardFromCard = (reviewId: string, card: Card): ReviewManualCard => {
//     let now = new Date();
//     return {
//         card: card, createdOn: now, reviewId: reviewId, updatedOn: now, userId: getUserId();
//     }
// }
